import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import UserContextProvider from './components/UserContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "@fontsource/roboto";
import './styles/style.scss';

import Icon2 from './images/icon2.png';
import Icon3 from './images/icon3.png';
import Icon4 from './images/icon4.png';
import Icon5 from './images/icon5.png';
import Icon6 from './images/icon6.png';
import Icon7 from './images/icon7.png';
import Icon8 from './images/icon8.png';
import Icon9 from './images/icon9.png';
import Icon10 from './images/icon10.png';
import Icon11 from './images/icon11.png';
import Icon12 from './images/icon12.png';
import Icon13 from './images/icon13.png';
import Err from './images/error.png';
import ImageDeleteIcon from './images/delete.png';
import MarkerMenu from './images/menu.png';

export { default as Error } from './components/Error';
export { default as Common } from './components/Common';
export { default as Main } from './components/Main';
export { default as Login } from './components/Login';
export { default as Old } from './components/Old';

export {
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Err,
  ImageDeleteIcon,
  MarkerMenu
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </StrictMode>
);serviceWorkerRegistration.register();