import { Autocomplete } from '@react-google-maps/api';

function SearchLocation({ onPlaceSelected }) {
  const handlePlaceSelect = (place) => {
    onPlaceSelected(place);
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => {
        autocomplete.addListener("place_changed", () => {
          handlePlaceSelect(autocomplete.getPlace());
        });
      }}
    >
      <input
        id="search-bar-input"
        type="text"
        placeholder="Wyszukaj miejsce"
      />
    </Autocomplete>
  );
}

export default SearchLocation;