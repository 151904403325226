import React, { useState, useContext } from "react";
import {Axios, UserContext} from './UserContext';

export default function Login(){

    /*

    const {loginUser, loggedInCheck} = useContext(UserContext);
    
    const [message, setMessage] = useState(false);

    const [formData, setFormData] = useState(
        {
            username: '',
            password: ''
        }
    );

    function formChange(event){
        const {name, value} = event.target;
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    const sendLogin = async(event) => {
        setMessage('');
        event.preventDefault();
        if(!formData.username || !formData.password){
            setMessage('Proszę wypełnić oba pola');
        } else {
            const data = await loginUser(formData);
            setMessage(data.message);
            await loggedInCheck();
            return;
        }
    }

    */

    const {loginUser, loggedInCheck} = useContext(UserContext);

    const [message, setMessage] = useState(false);

    const [formData, setFormData] = useState(
        {
            username: '',
            password: ''
        }
    );

    function formChange(event){
        const {name, value} = event.target;
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    const sendLogin = async(event) => {
        setMessage('');
        event.preventDefault();
        if(!formData.username || !formData.password){
            setMessage('Proszę wypełnić oba pola');
        } else {
            const data = await loginUser(formData);
            setMessage(data.message);
            await loggedInCheck();
            return;
        }
    }

    const [internetMessage, setInternetMessage] = useState(null);

    function connectInternet(){
        setInternetMessage(null);
        Axios.get('webcam1/modem.php', { timeout: 20000 }).then(function(response){
            if(response.data){
                setInternetMessage(response.data)    
            } else {
                setInternetMessage('Proces zakończył się niepowodzeniem');
            }
        }).catch((error) => {
            setInternetMessage('Proces zakończył się niepowodzeniem');
            console.log(error)
        });
    }

    return (
        <div id="login-outer-container">
            <div id="login-inner-container">
                <p id="login-form-title">Zaloguj się do aplikacji</p>
                <div className="form-line-wrapper">
                    <span className="login-form-label">Użytkownik:</span>
                    <input 
                        className="login-form"
                        id="username"
                        type="text"
                        onChange={formChange}
                        name="username"
                        value={formData.username}
                    />
                </div>
                <div className="form-line-wrapper">
                    <span className="login-form-label">Hasło:</span>
                    <input 
                        className="login-form"
                        id="password"
                        type="password"
                        onChange={formChange}
                        name="password"
                        value={formData.password}
                    />
                </div>
                {message && <p id="login-message">{message}</p>}
                <div className="form-line-wrapper">
                    <button id="send-login" className={message ? 'send-login-no-margin' : ''} onClick={sendLogin}>Zaloguj się</button>
                </div>
                {window.location.hostname !== 'kamery.rubika.pl' && <div className="form-line-wrapper">
                    <button className="app1-main-button app1-main-button-centered" onClick={() => connectInternet()}>Połącz internet</button>
                    {internetMessage && <p className='raspberry-message-span raspberry-message-span-centered'>{internetMessage}</p>}
                </div>}
            </div>
        </div>
    );
};  