import { Dexie } from 'dexie';

export const db = new Dexie('rubika_camera');

db.version(4).stores({
    user: '++index',
    map: '++index',
    last: '++index',
    cameras: '++index',
    disassembly: '++index',
});

db.open().catch(function (err) {
    console.error (err.stack || err);
});