import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from './UserContext';

export default function Common() {

    const {user} = useContext(UserContext);

    return (
        <>
            <div id="main-container">
                <div id="main-wrapper" className={(user && user.role !== 'none') ? "main-wrapper-lowered" : ""}>
                    <Outlet />
                </div>
            </div>
        </>
    )
}