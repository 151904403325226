import { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserContext } from './components/UserContext';
import { Common, Main, Error, Login, Old } from './index';
import CacheBuster from 'react-cache-buster';
import Loading from './components/Loading';
import packageInfo from '../package.json';

export default function App() {
    
    useEffect(() => {
        document.title = 'Rubika Webcam App 2';
    }, []);

    const {user} = useContext(UserContext);

    const isProduction = process.env.NODE_ENV === 'production';

    return ( 
        <CacheBuster
            currentVersion={packageInfo.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
            <>
                {user.role === 'none' && (
                    <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Common />}>
                            <Route index element={<Login />} />
                            <Route path='/old' element={<Old />} />
                            <Route path='*' element={<Error />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                )}
                {user.role !== 'empty' && user.role !== 'none' && (
                    <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Common />}>
                            <Route index element={<Main />} />
                            <Route path='/old' element={<Old />} />
                            <Route path='*' element={<Error />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                )}
            </>
        </CacheBuster>
    );
}