import { Link } from 'react-router-dom';
import Full404 from '../images/404.webp';

export default function Error(){
    return (
        <div className="image-container">
            <p id="text-404">Szukana strona nie istnieje na serwerze</p>
            <Link to="/">
                <img
                    src={Full404}
                    alt='404 error'
                    className="full-image"
                />
            </Link>
        </div>
    )
}