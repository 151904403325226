import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if(React.Children.count(children) === activeIndex){
      updateIndex(activeIndex - 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [React.Children.count(children)]);  

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  let paginationWrapper = document.querySelector('.pagination-wrapper');

  function prevSlide(){
    if(paginationWrapper){
      paginationWrapper.classList.add('transition-prev');
      updateIndex(activeIndex - 1);
      setTimeout(cleanClasses, 500);
    }
  }

  function nextSlide(){
    if(paginationWrapper){
      paginationWrapper.classList.add('transition-next');
      updateIndex(activeIndex + 1);
      setTimeout(cleanClasses, 500);
    }
  }

  function cleanClasses() {
    if(paginationWrapper.classList.contains('transition-next')) {
      paginationWrapper.classList.remove('transition-next')
    } else if(paginationWrapper.classList.contains('transition-prev')) {
      paginationWrapper.classList.remove('transition-prev')
    }
  }

  const slideCount = React.Children.count(children);

  return (
    <div
      {...handlers}
      className="carousel"
    >
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div id="carousel-slide-counter-wrapper">
        <p id="carousel-slide-counter">Zdjęcie {activeIndex+1}/{slideCount}</p>
      </div>
      <div className="pagination-wrapper indicators">
        <svg className="btn btn--prev" onClick={prevSlide} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
          <path d="M0-.5h24v24H0z" fill="none"/>
        </svg>
        <div className="pagination-container">
          <div className="little-dot  little-dot--first"></div>
          <div className="little-dot">
            <div className="big-dot-container">
              <div className="big-dot"></div>
            </div>
          </div>
          <div className="little-dot  little-dot--last"></div>
        </div>
        <svg className="btn btn--next" onClick={nextSlide} height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
          <path d="M0-.25h24v24H0z" fill="none"/>
        </svg>
      </div>
    </div>
  );
};

export default Carousel;