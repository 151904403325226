import React, { useState, useEffect, useRef, useContext } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { db } from "./Db";
import { SliderPicker } from "react-color";
import { GoogleMap, MarkerF, InfoWindowF, useJsApiLoader } from "@react-google-maps/api";
import { Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9, Icon10, Icon11, Icon12, Icon13, Err, ImageDeleteIcon, MarkerMenu } from '../index';
import Carousel, { CarouselItem } from "./Carousel";
import { UserContext, Axios } from './UserContext';
import SearchLocation from './SearchLocation';
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Logo from '../images/logo.png';
import LogoutImg from '../images/logout.png';
import {ReactComponent as MenuIcon1} from '../images/icon1.svg';
import {ReactComponent as MenuIcon2} from '../images/icon2.svg';
import {ReactComponent as MenuIcon3} from '../images/icon3.svg';
import {ReactComponent as MenuIcon4} from '../images/icon4.svg';
import {ReactComponent as MenuIcon5} from '../images/icon5.svg';
import {ReactComponent as Spinner} from '../images/spinner.svg';
import {ReactComponent as Spinner2} from '../images/spinner2.svg';

export default function Main(){

    // Warstwa aplikacji

    const [appLayer, setAppLayer] = useState();

    // Header

    const {user} = useContext(UserContext);

    const {logout} = useContext(UserContext);

    // Lokalizacja użytkownika

    const [userCoordinates, setUserCooridinates] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    useEffect(() => {

        if(user.role === 'read' || user.role === 'admin'){
            setAppLayer(300);
        } else if(user.role === 'user'){
            setAppLayer(100);
        }

        if(user.role !== 'read'){
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                setUserCooridinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  });
                },
                (error) => {
                    console.warn(error.message);
                },
                { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
            );
            return () => {
                navigator.geolocation.clearWatch(watchId);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ---------------------------------------------------------------------

    // Dodawanie kamer - 100

    const [time, setTime] = useState();

    const [day, setDay] = useState();

    const [year, setYear] = useState();

    const [datex, setDatex] = useState();

    const [IPcam, setIPcam] = useState();

    const [schedule, setSchedule] = useState();

    const [cameraPrepare, setCameraPrepare] = useState(false);

    const [cameraLogs, setCameraLogs] = useState({});

    const [cameraMessage, setCameraMessage] = useState();

    const [cameraWarning, setCameraWarning] = useState();

    const [raspberryMessage, setRaspberryMessage] = useState([]);

    const [raspberryErrorMessage, setRaspberryErrorMessage] = useState('');

    useEffect(() => {

        if(day){
            setYear(day.substring(day.length - 4));
        }

    }, [day]);

    // Zegarek

    useEffect(() => {

        setInterval(() => {

            const dateObject = new Date();
            const hour = dateObject.getHours();
            const minute = ('0'+dateObject.getMinutes()).slice(-2);
            const second = ('0'+dateObject.getSeconds()).slice(-2);
            const currentTime = hour + ':' + minute + ':' + second;

            setTime(currentTime);

        }, 1000);

        const newDay = new Date();

        setDay(newDay.toLocaleDateString('pl-PL', {day: 'numeric', month: 'numeric', year: 'numeric'}));

        setDatex(newDay.toLocaleDateString('fr-CA', {day: 'numeric', month: 'numeric', year: 'numeric'}));

    }, []);

    // Wykrywanie kamery

    function getCameraIP(){

        setModal({...modal, show: true, assembly_info: true});
        setCameraMessage();
        setCameraWarning();

        Axios.post('webcam1/ip_chk.php', { timeout: 20000 }).then(function(response){
            if(response.data){
                let IP;
                if(typeof response.data === 'number'){
                    IP = parseInt(response.data);
                } else {
                    const split = response.data.split(/\s+/);
                    IP = parseInt(split[0]);
                }
                if(!isNaN(IP)){
                    setIPcam(IP);
                    setCameraMessage('Wykryto kamerę IP '+IP);
                } else {
                    setCameraWarning('Nie udało się automatycznie wykryć kamery. Sprawdź połączenie i spróbuj ponownie.');
                }
            } else {
                setCameraWarning('Nie udało się automatycznie wykryć kamery. Sprawdź połączenie i spróbuj ponownie.');
            }
        }).catch((error) => {
            setCameraWarning('Nie udało się automatycznie wykryć kamery. Sprawdź połączenie i spróbuj ponownie.');
            console.log(error);
        });

    }

    // Programowanie kamery

    function programateCamera(){

        setModal({...modal, show: true, assembly: false, assembly_info: true});
        setCameraMessage();
        setCameraWarning();

        const data = new FormData();
        data.append('IPkamery', IPcam);
        Axios.post('webcam1/prepare.php', data ,{ timeout: 30000 }).then(function(response){
            if(response.data){
                let int = parseInt(response.data);
                if(int === 1){
                    setCameraMessage('Kamera została zaprogramowana.');
                    setCameraPrepare(true);
                } else {
                    setCameraWarning('Błąd programowania kamery!');
                }
            } else {
                setCameraWarning('Błąd programowania kamery!');
            }
        }).catch((error) => {
            console.log(error);
            setCameraWarning('Błąd programowania kamery!');
        });

    }

    // Anulowanie programowania przy zmianie kamery

    useEffect(() => {

        if(IPcam){
            setCameraPrepare(false);
        }

    }, [IPcam]);

    // Pobieranie logów

    useEffect(() => {

        if(cameraPrepare){
            const data = new FormData();
            data.append('IPkamery', IPcam);
            Axios.post('webcam1/logs.php', data ,{ timeout: 20000 }).then(function(response){
                if(typeof response.data === 'object' && response.data !== null){
                    setCameraLogs(response.data);
                } else {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraPrepare]);

    // Pola formularza

    const [raspberryForm, setRaspberryForm] = useState({
        ip_cam: '',
        schedule: ''
    });

    // Zmiana wartości

    function changeRaspberryForm(event){

        const {name, value} = event.target;

        if (!isNaN(value)) {
            setRaspberryForm(prevFormData => {
                return {
                    ...prevFormData,
                    [name]: value
                }
            });
        }

    }

    // Wybór IP kamery

    function setIP(){

        let value = parseInt(raspberryForm.ip_cam);

        if(isNaN(value) || value < 1 || value > 255){
            setRaspberryErrorMessage('Wpisz wartość z zakresu 1 - 255');
        } else {
            setRaspberryErrorMessage('');
            setIPcam(value);
            setRaspberryForm(prevFormData => {
                return {
                    ...prevFormData,
                    schedule: ''
                }
            });
            setSchedule();
            setRaspberryMessage([]);
            clearCurrentView();
        }

    }

    // Zatwierdzenie wyboru harmonogramu

    function acceptScheduleSelection(value){

        setRaspberryForm(prevFormData => {
            return {
                ...prevFormData,
                schedule: value
            }
        });

        setSchedule(value);

        setCameraSchedule(value);

        setCurrentView({...currentView, record_schedule: false});

    }

    // Przełączanie widoku

    const [currentView, setCurrentView] = useState({
        camera_submit: false,
        camera_list: false,
        time_check: false,
        time_update: false,
        record_schedule: false,
        record_on: false,
        record_off: false,
        prepare: false,
        sd_check: false,
        modem: false,
        updates: false,
        wifi_1: false,
        wifi_7: false,
        synch_time: false,
        point_name: false,
        point_number: false,
        mounting_point: false,
        platform_number: false,
        lane_number: false,
        comment: false,
        deactivate: false
    });

    function changeCurrentView(name){

        setCurrentView({
            camera_submit: name === 'camera_submit',
            camera_list: name === 'camera_list',
            time_check: name === 'time_check',
            time_update: name === 'time_update',
            record_schedule: name === 'record_schedule',
            record_on: name === 'record_on',
            record_off: name === 'record_off',
            prepare: name === 'prepare',
            sd_check: name === 'sd_check',
            modem: name === 'modem',
            updates: name === 'updates',
            wifi_1: name === 'wifi_1',
            wifi_7: name === 'wifi_7',
            synch_time: name === 'synch_time',
            point_name: name === 'point_name',
            point_number: name === 'point_number',
            mounting_point: name === 'mounting_point',
            platform_number: name === 'platform_number',
            lane_number: name === 'lane_number',
            comment: name === 'comment',
            deactivate: name === 'deactivate'
        });

        closeModal();

        switch(name){
            case "camera_list":
                standardGet('ip_chk');
                break;
            case "time_check":
                standardPost('time_chk');
                break;
            case "time_update":
                standardPost('time_akt');
                break;
            case "record_on":
                standardPost('rec_on');
                break;
            case "record_off":
                standardPost('rec_off');
                break;
            case "prepare":
                standardPost('prepare');
                break;
            case "sd_check":
                standardPost('sd_chk');
                break;
            case "modem":
                standardGet('modem');
                break;
            case "wifi_1":
                standardGet('wifi_1');
                break;
            case "wifi_7":
                standardGet('wifi_7');
                break;
            case "synch_time":
                synchTime();
                break;
            case "updates":
                checkUpdates();
                break;
            default:
                //
        }

    }

    function clearCurrentView(){

        setCurrentView({
            camera_submit: false,
            camera_list: false,
            time_check: false,
            time_update: false,
            record_schedule: false,
            record_on: false,
            record_off: false,
            prepare: false,
            sd_check: false,
            modem: false,
            updates: false,
            wifi_1: false,
            wifi_7: false,
            synch_time: false,
            point_name: false,
            point_number: false,
            mounting_point: false,
            platform_number: false,
            lane_number: false,
            comment: false,
            deactivate: false
        });

    }

    // --------------------------------------------------------------------------------

    // Do testowania z maliną

    function parseResponse(name, response){
        switch(name){
            case "ip_chk":
                const cams = response.split(/\s+/);
                setRaspberryMessage(cams);
                break;
            case "time_chk":
                const parts = response.split(/(Kamera|Czas)/).filter(part => part.trim() !== '');
                const chk_lines = [];
                for (let i = 0; i < parts.length; i += 2) {
                    chk_lines.push(parts[i] + parts[i + 1]);
                }
                setRaspberryMessage(chk_lines);
                break;
            case "time_akt":
                const akt_parts = response.split(/(Kamera|Nie|Ustawiono)/).filter(part => part.trim() !== '');
                const akt_lines = [];
                for (let i = 0; i < akt_parts.length; i += 2) {
                    akt_lines.push(akt_parts[i] + akt_parts[i + 1]);
                }
                setRaspberryMessage(akt_lines);
                break;
            case "rec_on":
            case "rec_off":
                const rec_parts = response.split(/(Kamera|Nagrywanie|Nie)/).filter(part => part.trim() !== '');
                const rec_lines = [];
                for (let i = 0; i < rec_parts.length; i += 2) {
                    rec_lines.push(rec_parts[i] + rec_parts[i + 1]);
                }
                setRaspberryMessage(rec_lines);
                break;
            case "schedule":
                const sch_parts = response.split(/(Wybrano|Kamera|Kasowanie|Nagrywam|Nie)/).filter(part => part.trim() !== '');
                const sch_lines = [];
                for (let i = 0; i < sch_parts.length; i += 2) {
                    sch_lines.push(sch_parts[i] + sch_parts[i + 1]);
                }
                setRaspberryMessage(sch_lines);
                break;
            case "prepare":
                const pre_parts = response.split(/(Kamera|Ustawiono|Nie|Nagrywanie|Zapis|Stan|Kasowanie|Nagrywam)/).filter(part => part.trim() !== '');
                const pre_lines = [];
                for (let i = 0; i < pre_parts.length; i += 2) {
                    pre_lines.push(pre_parts[i] + pre_parts[i + 1]);
                }
                setRaspberryMessage(pre_lines);
                break;
            case "sd_chk":
                const sd_parts = response.split(/(Kamera|Zapis|Stan|Pojemnosc)/).filter(part => part.trim() !== '');
                const sd_lines = [];
                for (let i = 0; i < sd_parts.length; i += 2) {
                    sd_lines.push(sd_parts[i] + sd_parts[i + 1]);
                }
                setRaspberryMessage(sd_lines);
                break;
            case "wifi_host1": 
            case "wifi_host7":
                setRaspberryMessage([response]);
                break;
            default:
                // 
        }
    }

    function standardGet(name){
        setRaspberryMessage([]);
        setRaspberryErrorMessage('');
        Axios.get('webcam1/'+name+'.php', { timeout: 20000 }).then(function(response){
            if(response.data){
                parseResponse(name, response.data)    
                setRaspberryErrorMessage('');
            } else {
                setRaspberryMessage([]);
                setRaspberryErrorMessage('Proces zakończył się niepowodzeniem');
            }
        }).catch((error) => {
            setRaspberryMessage([]);
            if(error.code === 'ECONNABORTED'){
                setRaspberryErrorMessage('Przekroczono czas połączenia.');
            } else {
                setRaspberryErrorMessage('Proces zakończył się niepowodzeniem');
            }
        });
    }

    function standardPost(name){
        setRaspberryMessage([]);
        setRaspberryErrorMessage('');
        if(!IPcam){
            setRaspberryMessage([]);
            setRaspberryErrorMessage('Proszę wybrać kamerę');
        } else {
            const data = new FormData();
            data.append('IPkamery', IPcam);
            Axios.post('webcam1/'+name+'.php', data ,{ timeout: 5000 }).then(function(response){
                if(response.data){
                    parseResponse(name, response.data);
                    setRaspberryErrorMessage('');
                } else {
                    setRaspberryMessage([]);
                    setRaspberryErrorMessage('Proces zakończył się niepowodzeniem.');
                }
            }).catch((error) => {
                setRaspberryMessage([]);
                if(error.code === 'ECONNABORTED'){
                    setRaspberryErrorMessage('Przekroczono czas połączenia.');
                } else {
                    setRaspberryErrorMessage('Proces zakończył się niepowodzeniem.');
                }
            });
        }
    }

    function setCameraSchedule(value){
        setRaspberryMessage([]);
        if(!IPcam){
            setRaspberryErrorMessage('Proszę wybrać kamerę');
        } else {
            const data = new FormData();
            data.append('IPkamery', IPcam);
            data.append('valdzien', value);
            Axios.post('webcam1/schedule.php', data ,{ timeout: 15000 }).then(function(response){
                if(response.data){
                    parseResponse('schedule', response.data);
                    setRaspberryErrorMessage('');
                } else {
                    setRaspberryMessage([]);
                    setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
                }
            }).catch((error) => {
                setRaspberryMessage([]);
                if(error.code === 'ECONNABORTED'){
                    setRaspberryErrorMessage('Przekroczono czas połączenia.');
                } else {
                    
                    setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
                }
            });
        }
    }
    
    function checkUpdates(){
        setRaspberryMessage([]);
        setRaspberryErrorMessage('');
        Axios.get('webcam1/update.php').then(function(response){
            if(response.data === true){
                setRaspberryMessage(['Aplikacja została zaktualizowana.']);
                clearCacheData();
                setRaspberryErrorMessage('');
            } else if(response.data === false) {
                setRaspberryMessage(['Brak dostępnej aktualizacji.']);
                setRaspberryErrorMessage('');
            } else {
                setRaspberryMessage([]);
                setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
            }
        }).catch((error) => {
            setRaspberryMessage([]);
            if(error.code === 'ECONNABORTED'){
                setRaspberryErrorMessage('Przekroczono czas połączenia.');
            } else {
                setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
            }
        });
    }

    function synchTime(){
        setRaspberryMessage([]);
        const data = new FormData();
        data.append('timex', time);
        data.append('datex', datex);
        Axios.post('webcam1/settime.php', data ,{ timeout: 10000 }).then(function(response){
            if(response.data){
                parseResponse("sync", response.data);
                setRaspberryErrorMessage('');
            } else {
                setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setRaspberryErrorMessage('Przekroczono czas połączenia.');
            } else {
                setRaspberryErrorMessage('Błąd sieciowy, proszę spróbować później.');
            }
        });
    }

    const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        setTimeout(
            function() {
                window.location.reload();
            }, 2000);
    };

    const [clickedView, setClickedView] = useState(false);

    function showCameraVideoPreview(){

        setClickedView(true);

        getPoints(null);

        let link = 'http://admin:rubika2020@192.168.1.'+IPcam+'/cgi-bin/mjpg/video.cgi?channel=1&subtype=1';
        let newTab = window.open(link, '_blank');
        newTab.focus();

    }

    // Pobieranie listy punktów do montażu kamer

    const [cameraPoints, setCameraPoints] = useState([]);

    const [cameraPointsUpdate, setCameraPointsUpdate] = useState(false);

    function showPointsChoice(){
        changeCurrentView('point_name');
        getPoints(null);
    }

    function getPoints(mapa){

        setCameraPointsUpdate(true);

        let map_name;
        
        if(!mapa){
            map_name = map.project_name;
        } else {
            map_name = mapa;
        }
        
        let request_type = 'get points';

        Axios.post('classes/camera.php', { map_name, request_type }, { timeout: 2000}).then((response)=>{
            if(Array.isArray(response.data)){
                if(mapa){
                    response.data.push({marker_name:"inny"});  
                }
                setCameraPoints(response.data);
            } else if(response.data === 0){
                setCameraPoints([]);
            } else {
                if(mapa){
                    setCameraPoints(['inny']);
                }
            }
            setCameraPointsUpdate(false);
        }).catch((error) => {
            console.log(error);
            if(mapa){
                setCameraPoints([]);
            }
            setCameraPointsUpdate(false);
        }); 

    }

    const [addCameraFormData, setAddCameraFormData] = useState(
        {
            add_point_name: '',
            add_point_number: '',
            add_mounting_point: '',
            add_platform_number: '',
            add_lane_number: '',
            add_photo: '',
            add_photo_error: '',
            add_comment: ''
        }
    );

    function addCameraFormChange(event){
        const {name, value} = event.target;
        setAddCameraFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    function acceptAddCameraButtonSelection(name, value){
        clearCurrentView();
        setAddCameraFormData(prevFormData => { return {...prevFormData, [name]: value}});
    }

    function addAssembledCamera(){

        let button = document.getElementById('add-camera-button');

        button.setAttribute("disabled", "disabled");

        setModal({...modal, show: true, assembly_add: true});

        setCameraMessage();

        setCameraWarning();

        const data = new FormData();

        data.append('map_name', map.project_name);

        data.append('latitude', userCoordinates.lat);

        data.append('longitude', userCoordinates.lng);

        data.append('camera_ip', IPcam);

        if(schedule){
            data.append('schedule', schedule);
        } else {
            data.append('schedule', '6');
        }

        if(addCameraFormData.add_point_name){
            data.append('point_name', addCameraFormData.add_point_name);
        }

        /*
        
        if(addCameraFormData.add_point_number){
            data.append('point_number', addCameraFormData.add_point_number);
        }

        */

        if(addCameraFormData.add_mounting_point){
            data.append('camera_mounting_point', addCameraFormData.add_mounting_point);
        }

        if(addCameraFormData.add_platform_number){
            data.append('platform', addCameraFormData.add_platform_number);
        }
        
        if(addCameraFormData.add_lane_number){
            data.append('lane', addCameraFormData.add_lane_number);
        }

        if(addCameraFormData.add_comment){
            data.append('marker_description', addCameraFormData.add_comment);
        }

        if(Object.hasOwn(cameraLogs, 'log_data')){
            data.append('camera_log_date', cameraLogs.log_data);
        }

        if(Object.hasOwn(cameraLogs, 'log_harmonogram')){
            data.append('camera_log_schedule', cameraLogs.log_harmonogram);
        }

        if(Object.hasOwn(cameraLogs, 'log_nagrywanie')){
            data.append('camera_log_recording', cameraLogs.log_nagrywanie);
        }

        if(Object.hasOwn(cameraLogs, 'log_inne')){
            data.append('camera_log_other', cameraLogs.log_inne);
        }

        data.append('username', user.name);

        data.append('request_type', 'add assembled camera');
    
        Axios.post('classes/camera.php', data , { timeout: 30000}).then((response)=>{
            if(response.data === true){
                setCameraMessage('Kamera została poprawnie dodana');
                clearCameraAdd();
            } else if(response.data.text){
                const text = response.data.text;
                if(response.data.file){
                    text.image = response.data.file;
                }
                saveCameraOffline(text);
                setCameraWarning("Brak połączenia z bazą danych. Kamera zostanie dodana automatycznie gdy połączenie zostanie przywrócone.");
            } else {
                setCameraWarning("Błąd sieciowy");
            }
            button.removeAttribute("disabled");
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setCameraWarning("Przekroczono czas połączenia.");
            } else {
                setCameraWarning("Błąd sieciowy");
            }
            button.removeAttribute("disabled");
        });
    }

    function saveCameraOffline(data){
        db.cameras.put(data);
    }

    function clearCameraAdd(){

        setAddCameraFormData({
            add_point_number: '',
            add_mounting_point: '',
            add_platform_number: '',
            add_lane_number: '',
            add_photo: '',
            add_photo_error: '',
            add_comment: ''
        });

        setIPcam();

        setSchedule();

        setCameraLogs({});

        setRaspberryMessage([]);

        setRaspberryErrorMessage('');

        setRaspberryForm({
            ip_cam: '',
            schedule: ''
        });

        setCameraPrepare(false);

        setClickedView(false);

    }

    useEffect(() => {

        const h = setInterval(() => {

            db.cameras.toArray().then(function(result){
                if(result.length > 0){
                    sendCamerasFromDB(result);
                }       
            });

            function sendCamerasFromDB(data){
                
                let request_type = 'save camerasDB';

                Axios.post('classes/camera.php', { data, request_type }, { timeout: 4000 }).then((response)=>{
                    if(Array.isArray(response.data)){
                        let indexes = response.data;
                        db.cameras.where('index').anyOf(indexes).delete();
                    }
                }).catch((error) => {
                    console.log(error);
                });
                
            }

        }, 60000);

        return () => { clearInterval(h) }
        
    }, []);

    // ---------------------------------------------------------------------

    // Main - 300

    const [projects, setProjects] = useState([]);

    const [axiosError, setAxiosError] = useState(null);

    // Menu

    const [menu, setMenu] = useState(
        {
            main: '',
            layer: ''
        }
    );

    function openMainMenu(){
        if(!menu.main){
            setMenu({...menu, main: 1});
        } else {
            setMenu({...menu, main: ''});
        }
    }

    function clearMainMenu(){
        setMenu({...menu, main: ''});
    }

    const [menuToggleable, setMenuToggleable] = useState(true);

    function openLayerMenu(id, index){

        const parentRect = document.getElementById('layer-container').getBoundingClientRect();
        const childRect = document.getElementById(['layer-wrapper-'+index]).getBoundingClientRect();
        const distance = parentRect.bottom - childRect.top;

        if(distance < 150){
            setMenuToggleable(false);
        } else {
            setMenuToggleable(true);
        }

        if(id === menu.layer){
            setMenu({...menu, layer: ''});
        } else {
            setMenu({...menu, layer: id});
        }
        
    }

    function hideLayer(id){
        let el = document.querySelector("#layer-"+id);
        let but = document.querySelector("#quick-layer-hide-"+id);
        if(el.classList.contains('layer-hidden')){
            el.classList.remove('layer-hidden');
            but.innerText = '▼';
        } else {
            el.classList.add('layer-hidden');
            but.innerText = '►';
        }
    }

    function clearLayerMenu(){
        setMenu({...menu, layer: ''});
    }

    // Modale

    const [modal, setModal] = useState(
        {
            show: false,
            create: false,
            open: false,
            rename_map: false,
            import_map: false,
            export_map: false,
            delete: false,
            rename_layer: false,
            color_layer: false,
            delete_layer: false,
            camera_add: false,
            camera_remove: false,
            mobile_marker_menu: false,
            marker_rename: false,
            marker_description: false,
            marker_move: false,
            marker_color: false,
            marker_delete: false,
            assembly: false,
            assembly_info: false,
            assembly_add: false,
            disassembly_info: false,
            waiting: false,
            message: false
        }
    );

    function closeModal(){
        if(modal.waiting){
            return;
        }
        setModal({
            show: false,
            create: false,
            open: false,
            rename_map: false,
            import_map: false,
            export_map: false,
            delete: false,
            rename_layer: false,
            color_layer: false,
            delete_layer: false,
            camera_add: false,
            camera_remove: false,
            mobile_marker_menu: false,
            marker_rename: false,
            marker_description: false,
            marker_move: false,
            marker_color: false,
            marker_delete: false,
            assembly: false,
            assembly_info: false,
            assembly_add: false,
            disassembly_info: false,
            waiting: false,
            message: false
        });
        setModalMessage(null);
        setModalErrorMessage(null);
        document.body.style.overflow = '';
    }

    const [modalSpin, setModalSpin] = useState(false);

    const [buttonSpin, setButtonSpin] = useState(false);

    const [waitingTitle, setWaitingTitle] = useState(null);

    const [modalMessage, setModalMessage] = useState(null);

    const [modalErrorMessage, setModalErrorMessage] = useState(null);

    // Formularze

    const [formData, setFormData] = useState(
        {
            project_name: '',
            project_description: '',
            map_rename: '',
            layer_rename: '',
            marker_rename: '',
            marker_description: '',
            point_number: '',
            platform: '',
            lane: '',
            comment: '',
            mounting_point: '',
            marker_select: ''
        }
    );

    function formChange(event){
        const {name, value} = event.target;
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    const handleChange = event => {
        setFormData({...formData, [event.target.name]: event.target.value});
    }

    function clearForm(){
        setFormData({project_name: '', project_description: '', map_rename: '', layer_rename: '', marker_rename: '', marker_description: '', point_number: '', platform: '', lane: '', comment: '', mounting_point: '', marker_select: ''})
    }

    // Tworzenie nowej mapy

    function createMapMenu(){
        clearMainMenu();
        setModal({...modal, show: true, create: true});
        setAxiosError(null);
    }

    function createMap(){
        
        let button = document.getElementById('create-map-button');
        button.setAttribute("disabled", "disabled");
        let request_type = 'create map';

        if (formData.project_name){
            setModalSpin(true);
            let form = formData;
            Axios.put('classes/project.php', { request_type, form }, { timeout: 5000 }).then(function(response){
                if (Object.hasOwn(response.data, 'count') === true){
                    setAxiosError('Już istnieje projekt o tej nazwie.');
                } else if(Object.hasOwn(response.data, 'id') === true){
                    setAxiosError(null);
                    openMap(response.data.id, formData.project_name, formData.project_description, response.data.modified);
                    setCenter({lat: 54.35384251305164, lng: 18.643928281864885});
                    setActiveLayer({id: '', name: ''});
                    setMarkers([]);
                    setActiveMarker([]);
                    clearForm();
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            });
        } else {
            setAxiosError("Podaj nazwę projektu.");
            button.removeAttribute("disabled");
        }

    }

    // Otwieranie mapy

    function openMapMenu(){
        setAxiosError(null);
        clearMainMenu();
        setModal({...modal, show: true, open: true});
        getProjects(null);
    }

    function getProjects(saved) {

        Axios.get('classes/project.php', { timeout: 5000 }).then(function(response){
            if(Array.isArray(response.data)){
                setProjects(response.data);
                if(saved){
                    if(response.data.some(obj => obj.project_name === saved.project_name)){
                        const mapFromDB = response.data.filter(obj => obj.project_name === saved.project_name);
                        openMap(mapFromDB[0].id, mapFromDB[0].project_name, mapFromDB[0].project_description, mapFromDB[0].modified, mapFromDB[0].center_lat, mapFromDB[0].center_lng, mapFromDB[0].zoom);
                    } else {
                        db.map.clear();
                    }
                }
                if(appLayer === 200){
                    setDisassemblyProject(response.data[0].project_name);
                }
                setAxiosError(null);
            } else if (response.data === 0) {
                setAxiosError('Brak map w bazie, proszę stworzyć nową.');
            } else {
                setAxiosError('Błąd sieciowy, proszę spróbować później.');
            }
        }).catch((error) => {
            console.log(error);
            if(saved){
                openMap(saved.id, saved.project_name, saved.project_description, saved.modified, saved.lat, saved.lng, saved.zoom);
            } else {
                setAxiosError('Błąd sieciowy, proszę spróbować później.');
            }
        });

    }

    const [map, setMap] = useState(
        {
            id: '',
            project_name: '',
            project_description: '',
            modified: '',
            lat: '',
            lng: '',
            zoom: ''
        }
    );

    function openMap(id, name, description, modified, lat, lng, zoom){
        setLayerError('');
        closeModal();
        if (map.id !== id){
            setLayers([]);
            setActiveLayer({id: '', name: ''});
            setMarkers([]);
            setActiveMarker([]);
            setMarkerImages([]);
            getLayers(name);
            setMap({id: id, project_name: name, project_description: description, modified: modified, lat: lat, lng: lng, zoom: zoom});
            if (lat){
                setCenter({lat: lat, lng: lng});
            } else {
                setCenter({lat: 54.35384251305164, lng: 18.643928281864885});
            }
            if (zoom){
                setZoom(zoom);
            } else {
                setZoom(12);
            }
            db.map.toArray().then(function(result){
                if(result.length > 0){
                    db.map.update(1, {'id': id, 'project_name': name, 'project_description': description, 'modified': modified, 'lat': lat, 'lng': lng, 'zoom': zoom});
                } else {
                    db.map.put({'index': 1, 'id': id, 'project_name': name, 'project_description': description, 'modified': modified, 'lat': lat, 'lng': lng, 'zoom': zoom});
                }
            });
        }
    }

    // Zmiana nazwy mapy

    function renameMapMenu(){
        setAxiosError(null);
        clearMainMenu();
        setModal({...modal, show: true, rename_map: true});
        setFormData({...formData, map_rename: map.project_name});
    }

    function renameMap(){

        let map_name = map.project_name;
        let new_name = formData.map_rename;

        if(new_name && (map_name !== new_name)){

            setModalSpin(true);
            let button = document.getElementById('rename-map-button');
            button.setAttribute("disabled", "disabled");
            let request_type = 'rename project';

            Axios.post('classes/project.php', { map_name, new_name, request_type }, { timeout: 5000}).then((response)=>{
                if (response.data === true){
                    setAxiosError(null);
                    setMap({...map, project_name: new_name});
                    closeModal();
                    clearForm();
                } else if (response.data === false) {
                    setAxiosError("Już istnieje mapa o tej nazwie.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }); 
        } else if(new_name && (map_name === new_name)){
            setAxiosError(null);
            closeModal();
        }

    }

    // Import mapy

    const [KML, setKML] = useState();

    function importMapMenu(){
        clearMainMenu();
        setModal({...modal, show: true, import_map: true});
        setAxiosError(null);
    }

    function handleKMLDrop(e) {
        let name = e.dataTransfer.files[0].name;
        if(verifyKML(name) === true){
            setKML(e.dataTransfer.files[0]);
        }
    }

    function KMLchange(e) {
        let name = e.target.files[0].name;
        if(verifyKML(name) === true){
            setKML(e.target.files[0]);
        }
    }

    function verifyKML(name){
        var type = name.substr( name.indexOf('.') + 1 );
        if(type === 'kml'){
            setAxiosError(null);
            return true;
        } else {
            setAxiosError('Wybrany plik nie jest w formacie KML');
            return false;
        }
    }

    function importMap(){
        if(KML){
            setModalSpin(true);
            const data = new FormData();
            data.append('kml', KML);
            data.append('request_type', 'import');

            Axios.post('classes/export.php', data).then((response)=>{
                if(Array.isArray(response.data.map)){
                    let map = response.data.map[0];
                    openMap(map.id, map.project_name, map.project_description, map.modified, map.center_lat, map.center_lng, map.zoom);
                    closeModal();
                    setAxiosError(null);
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            });
        }
    }

    // Eksport mapy

    const [mapExport, setMapExport] = useState('CSV');

    const onRadioChange = e => {
        setMapExport(e.target.value);
    }

    function exportMapMenu(){
        clearMainMenu();
        setModal({...modal, show: true, export_map: true});
        setAxiosError(null);
    }

    function exportMap(){

        setModalSpin(true);
        let map_name = map.project_name;
        let button = document.getElementById('export-map-button');
        button.setAttribute("disabled", "disabled");
        let request_type = mapExport;

        Axios.post('classes/export.php', { map_name, request_type }, { timeout: 15000 }).then((response)=>{
            if(response.data.download){
                setAxiosError(null);
                downloadFile(response.data.download, response.data.filename);
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setAxiosError("Przekroczono czas połączenia.");
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        }); 

    }

    function downloadFile(url, filename){
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Usunięcie mapy

    function deleteMapMenu(){
        setAxiosError(null);
        clearMainMenu();
        getProjects(null);
        setModal({...modal, show: true, delete: true});
    }

    const [deleteInfo, setDeleteInfo] = useState({id: []});

    const updateDeleteList = (e) => {

        const { value, checked } = e.target;
        const { id } = deleteInfo;

        if (checked) {
            setDeleteInfo({
                id: [...id, value],
            });
            return deleteInfo;
        }

        else {
            setDeleteInfo({
                id: id.filter((e) => e !== value),
            });
            return deleteInfo;
        }

    };

    function deleteMap(){
        
        if (deleteInfo.id[0]){

            let button = document.getElementById('delete-map-button');
            button.setAttribute("disabled", "disabled");
            let deleteList = deleteInfo.id;
            setModalSpin(true);

            Axios.delete('classes/project.php', { data: { deleteList } }).then(function(response){
                if(response){
                    let currentId = (map.id).toString();
                    if (deleteList.includes(currentId)){
                        db.map.clear();
                        setMap({id: '', project_name: '', project_description: '', modified: '', lat: '', lng: '', zoom: ''});
                        setActiveLayer({id: '', name: ''});
                        setLayers([]);
                        setMarkers([]);
                        setActiveMarker([]);
                        setAxiosError(null);
                    }
                    deleteInfo.id = [];
                    if(response.data === 0){
                        setProjects([]);
                        setAxiosError("Brak map w bazie.");
                    } else {
                        setProjects(response.data);
                        setAxiosError("");
                    }
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            });
        } 
    }

    // Parametry mapy
    
    const containerStyle = {
        width: "100%",
        height: "450px",
    }

    const [center, setCenter] = useState(
        {
            lat: 54.35384251305164,
            lng: 18.643928281864885
        }
    );

    const [zoom, setZoom] = useState(12);

    function centerMap(){

        let map_name = map.project_name;
        let lat = loggedCoordinates.lat;
        let lng = loggedCoordinates.lng;
        let zoom = mapElement.current.state.map.zoom;
        let request_type = 'center project';

        let div = document.getElementById('add-marker-spinner-wrapper');

        let div2 = document.getElementById('center-map-wrapper');

        Object.assign(div.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 

        Axios.post('classes/project.php', { map_name, lat, lng, zoom, request_type }, { timeout: 5000}).then(function(response){
            if(response.data === true){
                Object.assign(div.style,{display: "none"}); 
                div2.innerHTML = 'ok';
                Object.assign(div2.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 
            } else {
                Object.assign(div.style,{display: "none"}); 
                div2.innerHTML = 'błąd';
                Object.assign(div2.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 
            }
            fade(div2);
        }).catch((error) => {
            console.log(error);
            Object.assign(div.style,{display: "none"}); 
            div2.innerHTML = 'błąd';
            Object.assign(div2.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 
            fade(div2);
        });

    }

    function fade(element) {
        var op = 1;  // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.1){
                clearInterval(timer);
                element.style.display = 'none';
            }
            element.style.opacity = op;
            element.style.filter = 'alpha(opacity=' + op * 75 + ")";
            op -= op * 0.075;
        }, 50);
    }

    // Zapisywanie koordynatów

    const [loggedCoordinates, setLoggedCoordinates] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    const mapElement = useRef();

    const logClick = (event) => {
        let lat = event.latLng.lat();
        let lng = event.latLng.lng();
        setLoggedCoordinates({lat: lat, lng: lng});
    }

    // Tworzenie nowej warstwy

    const [layers, setLayers] = useState([]);

    const [markers, setMarkers] = useState([]);

    const [layerError, setLayerError] = useState(null);

    function addLayer(){

        let button = document.getElementById('add-layer-button');
        button.setAttribute("disabled", "disabled");
        setButtonSpin(true);

        setLayerError(null);

        let map_name = map.project_name;
        let request_type = 'create layer';

        Axios.post('classes/layer.php', { map_name, request_type }, { timeout: 5000}).then((response)=>{
                if(response.data.layer){
                    setLayers(response.data.layer);
                    setLayerError(null);
                } else {
                    setLayerError("Błąd sieciowy, proszę spróbować później.");
                }
                setButtonSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setLayerError("Przekroczono czas połączenia.");
                } else {
                    setLayerError("Błąd sieciowy, proszę spróbować później.");
                }
                setButtonSpin(false);
                button.removeAttribute("disabled");
            }
        );  
    }

    // Pobranie listy warstw

    function getLayers(name){

        setLayerError(null);

        let map_name = name;
        let request_type = 'get layers';

        Axios.post('classes/layer.php', { map_name, request_type }, { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data.layer)){
                setLayers(response.data.layer);
                if(response.data.markers){
                    setMarkers(response.data.markers);
                }
                setLayerError(null);
            } else if (response.data.layer === 0){
                setLayers([]);
                setLayerError(null);
            } else {
                setLayerError("Błąd sieciowy, proszę spróbować później.");
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setLayerError("Przekroczono czas połączenia.");
            } else {
                setLayerError("Błąd sieciowy, proszę spróbować później.");
            }
        });  
        
    }

    // Działania na warstwie

    const [activeLayer, setActiveLayer] = useState({id: '', name: ''});

    function activateLayer(event, id, name){
        if(event){
            if(event.target.tagName !== 'INPUT' || event.target.checked){
                setActiveLayer({id: id, name: name}); 
            }
        }
    }

    // Pokazywanie i ukrywanie warstwy

    const toggleLayer = (e) => {

        const { value, checked } = e.target;
        let div = document.getElementById('layer-' +value);

        if (checked) {
            div.style.setProperty("display", "block");
            if(markers[0]){
                showMarkers(value);
            }
        } else {
            if(activeLayer.id === parseInt(value)){
                setActiveLayer({id: '', name: ''});
            }
            if(activeMarker.layer_id === parseInt(value)){
                setActiveMarker([]);
                setMarkerImages([]);
            }
            if(markers[0]){
                hideMarkers(value);
            }
            div.style.setProperty("display", "none");
        }

    }

    // Zmiana nazwy warstwy

    function showRenameLayerMenu(id, name){
        setAxiosError(null);
        clearLayerMenu();
        setFormData({...formData, layer_rename: name});
        setModal({...modal, show: true, rename_layer: true});
        setActiveLayer({id: id, name: name});
    }

    function renameLayer(){

        if(formData.layer_rename){

            if(formData.layer_rename !== activeLayer.name){

                setModalSpin(true);

                let button = document.getElementById('rename-layer-button');
                button.setAttribute("disabled", "disabled");
                let map_name = map.project_name;
                let layer_id = activeLayer.id;
                let layer_new_name = formData.layer_rename;
                let request_type = 'rename layer';
        
                Axios.post('classes/layer.php', { map_name, layer_id, layer_new_name, request_type }, { timeout: 5000}).then((response)=>{
                    if (response.data.layer){
                        setLayers(response.data.layer);
                        setUpdatedMarkers(markers.map(obj => {
                            if (obj.layer_id === activeLayer.id) {
                                return { ...obj, layer_name: layer_new_name };
                            } else {
                                return obj;
                            }
                        }));
                        setAxiosError(null);
                        clearForm();
                        closeModal();
                    } else {
                        setAxiosError("Błąd sieciowy, proszę spróbować później.");
                    }
                    setModalSpin(false);
                    button.removeAttribute("disabled");
                }).catch((error) => {
                    if(error.code === 'ECONNABORTED'){
                        setAxiosError("Przekroczono czas połączenia.");
                    } else {
                        setAxiosError("Błąd sieciowy, proszę spróbować później.");
                    }
                    setModalSpin(false);
                    button.removeAttribute("disabled");
                });  

            } else {

                closeModal();

            }

        }

    }

    // Usuwanie warstwy

    function showDeleteLayerMenu(id, name){
        setAxiosError(null);
        clearLayerMenu();
        setModal({...modal, show: true, delete_layer: true});
        setActiveLayer({id: id, name: name});
    }

    function deleteLayer(){

        let button = document.getElementById('delete-layer-button');
        button.setAttribute("disabled", "disabled");
        let map_name = map.project_name;
        let layer_id = activeLayer.id;
        let request_type = 'delete layer';
        setModalSpin(true);

        Axios.post('classes/layer.php', { map_name, layer_id, request_type }, { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data.layer)){
                setLayers(response.data.layer);
                if(response.data.markers){
                    setMarkers(response.data.markers);
                    if(activeMarker.layer_id === layer_id){
                        setActiveMarker([]);
                    }
                } else {
                    setMarkers([]);
                    setActiveMarker([]);
                }
                setActiveLayer({id: '', name: ''});
                setMarkerImages([]);
                setAxiosError(null);
                closeModal();
            } else if (response.data.layer === 0){
                setLayers([]);
                setMarkers([]);
                setActiveMarker([]);
                setMarkerImages([]);
                setActiveLayer({id: '', name: ''});
                setAxiosError(null);
                closeModal();
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setAxiosError("Przekroczono czas połączenia.");
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        });  

    }

    // Menu kontekstowe na mapie

    const [loggedPosition, setLoggedPosition] = useState({x: null, y: null});

    const showContextMenu = (event) => {

        const classNames = [...event.target.classList];

        const classes = ['gm-style-iw', 'gm-style-iw-c', 'gm-title', 'address-line', 'view-link'];

        let found = [];

        if(classNames.length > 0){

            found = classNames.some(r=> classes.includes(r));

        } else {

            found = false;

        }

        if(event.target.tagName !== 'IMG' && event.target.tagName !== 'P' && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'AREA' && event.target.tagName !== 'INPUT' && !draggable && !found){

            let div = document.getElementById('map-inner-container');
            let offset = div.getBoundingClientRect();
            let menu_div = document.getElementById('add-context-menu-wrapper');
            const X = event.clientX - offset.left - 85;

            setLoggedPosition({x: event.clientX - offset.left -20, y: event.clientY - offset.top -20});

            if (map.id && !activeLayer.id){
                const Y = event.clientY - offset.top -75;
                Object.assign(menu_div.style,{left: X+"px",top: Y+"px",height: "50px",display: "block"});            
            } else if (map.id && activeLayer.id){
                const Y = event.clientY - offset.top -125;
                Object.assign(menu_div.style,{left: X+"px",top: Y+"px",height: "100px",display: "block"});  
            }
        } else {

            clearContextMenu();

        }

    }

    function clearContextMenu(){
        if(map.id){
            let div = document.getElementById('add-context-menu-wrapper');
            div.style.setProperty("display", "none");
        }
    }

    // Dodawanie markera

    const [activeMarker, setActiveMarker] = useState([]);

    function addMarker(){

        let map_name = map.project_name;
        let layer_id = activeLayer.id;
        let lat = loggedCoordinates.lat;
        let lng = loggedCoordinates.lng;
        let request_type = 'add marker';

        let div = document.getElementById('add-marker-spinner-wrapper');

        let div2 = document.getElementById('center-map-wrapper');

        Object.assign(div.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"});  
        
        Axios.post('classes/marker.php', { map_name, layer_id, lat, lng, request_type }, { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data.markers)){
                setMarkers([]);
                setUpdatedMarkers(response.data.markers);
                let index = response.data.markers_count;
                setActiveMarker(response.data.markers[index]);
            } else {
                div2.innerHTML = 'błąd';
                Object.assign(div2.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 
                fade(div2);
            }
            Object.assign(div.style,{display: "none"}); 
        }).catch((error) => {
            console.log(error);
            Object.assign(div.style,{display: "none"}); 
            div2.innerHTML = 'błąd';
            Object.assign(div2.style,{left: loggedPosition.x+"px",top: loggedPosition.y+"px",display: "block"}); 
            fade(div2);
        });         

    }

    // Ustawienie koloru warstwy

    const [color, setColor] = useState({
        active: '',
        color: '#ea4335'
    });

    function showLayerColorChange(){
        setModal({...modal, show: true, color_layer: true});
        setAxiosError(null);
    }

    function changeLayerColor(){

        if (activeLayer.id){
            setColor({...color, active: ''});
            let map_name = map.project_name;
            let layer_id = activeLayer.id;
            let new_color = color.color;
            let request_type = 'set color';
            setModalSpin(true);
            setMarkers([]);
    
            Axios.post('classes/layer.php', { map_name, layer_id, new_color, request_type }, { timeout: 5000}).then((response)=>{
                if (Array.isArray(response.data.markers)){
                    setUpdatedMarkers(response.data.markers);
                    setAxiosError(null);
                    closeModal();
                } else if (Array.isArray(response.data.layer)) {
                    setAxiosError(null);
                    closeModal();
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            });  
        }
    }

    // Otwieranie markera

    function openMarker(index) {
        let id = markers[index].id;
        let type = markers[index].type;
        if(id !== activeMarker.id || type !== activeMarker.type){
            setArchiveMessage(null);
            setMarkerImages([]);
            if(draggable === true){
                disableMarkerDragging(activeMarker.id);
            }
            clearMarkerForm();
            setActiveMarker(markers[index]);
        }
    }

    useEffect(() => {
        if(activeMarker.hasOwnProperty('id')){
            setCenter({lat: activeMarker.latitude, lng: activeMarker.longitude});
            setActiveLayer({id: activeMarker.layer_id, name: activeMarker.layer_name});
            getImages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMarker]);

    // Aktualizowanie markerów

    const [updatedMarkers, setUpdatedMarkers] = useState([]);

    const hideMarkers = (id) => {
        let layer_id = parseInt(id);
        setUpdatedMarkers(
            markers.map((marker) => {
                if (marker.layer_id === layer_id) {
                    return { ...marker, visible: false };
                } else {
                return marker;
                }
            })
        );
    }

    const showMarkers = (id) => {
        let layer_id = parseInt(id);
        setUpdatedMarkers(
            markers.map((marker) => {
                if (marker.layer_id === layer_id) {
                    return { ...marker, visible: 'true' };
                } else {
                return marker;
                }
            })
        );
    }

    // Formularze markerów

    const [markerForm, setMarkerForm] = useState({
        active: '',
        image: ''
    });

    const [markerError, setMarkerError] = useState(null);

    function clearMarkerForm(){
        setMarkerForm({
            active: '',
            image: ''
        });
        setMarkerError('');
    }

    // Zmiana nazwy markera

    function showMarkerRename(){
        setFormData({...formData, marker_rename: activeMarker.marker_name});
        setModal({...modal, show: true, mobile_marker_menu: false, marker_rename: true});
        setAxiosError(null);
    }
    
    function renameMarker(){
        let old_name = activeMarker.marker_name;
        let marker_name = formData.marker_rename;

        if(marker_name && (old_name !== marker_name)){
            setModalSpin(true);
            let button = document.getElementById('rename-marker-button');
            button.setAttribute("disabled", "disabled");
            let marker_id = activeMarker.id;
            let type = activeMarker.type;
            let map_name = map.project_name;
            let request_type = 'rename marker';
            //setMarkers([]);

            Axios.post('classes/marker.php', { marker_id, map_name, marker_name, type, request_type }, { timeout: 5000}).then((response)=>{
                if (Array.isArray(response.data.markers)){
                    setMarkers([]);
                    closeModal();
                    clearForm();
                    setUpdatedMarkers(response.data.markers);
                    setActiveMarker({...activeMarker, marker_name: marker_name});
                    setAxiosError(null);
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            });  
        } else if(marker_name && (old_name === marker_name)){
            closeModal();
        }
    }

    // Zmiana opisu markera

    function showMarkerDescription(){
        let description = activeMarker.marker_description;
        if(description){
            setFormData({...formData, marker_description: description});
        }
        setModal({...modal, show: true, mobile_marker_menu: false, marker_description: true});
        setAxiosError(null);
    }

    function changeMarkerDescription(){
        let old_description = activeMarker.marker_description;
        let marker_description = formData.marker_description;

        if(old_description !== marker_description){
            let button = document.getElementById('set-marker-description');
            button.setAttribute("disabled", "disabled");
            let marker_id = activeMarker.id;
            let type = activeMarker.type;
            let map_name = map.project_name;
            let request_type = 'describe marker';
            setModalSpin(true);
    
            Axios.post('classes/marker.php', { marker_id, map_name, marker_description, type, request_type }, { timeout: 5000}).then((response)=>{
                if (Array.isArray(response.data.markers)){
                    setMarkers(response.data.markers);
                    closeModal();
                    clearForm();
                    setActiveMarker({...activeMarker, marker_description: marker_description});
                    setAxiosError(null);
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
                button.removeAttribute("disabled");
            });  
        } else {
            closeModal();
        } 
    }

    // Przesuwanie markera

    const [draggable, setDraggable] = useState(false);

    function enableMarkerDragging(){
        setAxiosError(null);
        let element = document.getElementById('map-inner-container');
        element.scrollIntoView();//{ behavior: 'smooth' }
        setCenter({lat: activeMarker.latitude, lng: activeMarker.longitude});
        setModal({...modal, show: true, mobile_marker_menu: false, marker_move: true});
        let id = activeMarker.id;
        let type = activeMarker.type;
        setDraggable(true);
        setUpdatedMarkers(
            markers.map((marker) => {
                if (marker.id === id && marker.type === type) {
                    return { ...marker, draggable: 'true'};
                } else {
                    return marker;
                }
            })
        );
    }

    function disableMarkerDragging(id){
        setDraggable(false);
        setNewCoordinates({lat: '', lng: ''});
        setUpdatedMarkers(
            markers.map((marker) => {
                if (marker.id === id) {
                    return { ...marker, latitude: activeMarker.latitude, longitude: activeMarker.longitude, draggable: 'false'};
                } else {
                    return marker;
                }
            })
        );
    }

    const [newCoordinates, setNewCoordinates] = useState(
        {
            lat: '',
            lng: ''
        }
    );

    const markerDrag = (event, id) => {
        let long_lat = event.latLng.lat();
        let long_lng = event.latLng.lng();
        let lat = parseFloat(long_lat.toFixed(8));
        let lng = parseFloat(long_lng.toFixed(8));
        updateMarkerPosition(id, lat, lng);
        setNewCoordinates({lat: lat, lng: lng});
        setAxiosError(null);
    }

    const updateMarkerPosition = (id, lat, lng) => {
        setUpdatedMarkers(
            markers.map((marker) => {
                if (marker.id === id) {
                    return { ...marker, latitude: lat, longitude: lng };
                } else {
                return marker;
                }
            })
        );
    }

    function changeMarkerPosition(){
        let old_latitude = activeMarker.latitude;
        let old_longitude = activeMarker.longitude;
        let latitude = newCoordinates.lat;
        let longitude = newCoordinates.lng;

        if(!latitude || !longitude){
            disableMarkerDragging(activeMarker.id);
        } else {
            if((old_latitude !== latitude) || (old_longitude !== longitude)){
                let marker_id = activeMarker.id;
                let type = activeMarker.type;
                let map_name = map.project_name;
                let request_type = 'change location';
                let button = document.getElementById('marker-drag-button');
                setModalSpin(true);
                button.setAttribute("disabled", "disabled");
                
                Axios.post('classes/marker.php', { marker_id, map_name, type, latitude, longitude, request_type }, { timeout: 5000}).then((response)=>{
                    if (Array.isArray(response.data.markers)){
                        setActiveMarker({...activeMarker, latitude: newCoordinates.lat, longitude: newCoordinates.lng});
                        setUpdatedMarkers(response.data.markers);
                        setDraggable(false);
                        setNewCoordinates({lat: '', lng: ''});
                        setAxiosError(null);
                    } else {
                        setAxiosError("Błąd sieciowy, proszę spróbować później.");
                    }
                    setModalSpin(false);
                    button.removeAttribute("disabled");
                }).catch((error) => {
                    if(error.code === 'ECONNABORTED'){
                        setAxiosError("Przekroczono czas połączenia.");
                    } else {
                        setAxiosError("Błąd sieciowy, proszę spróbować później.");
                    }
                    setModalSpin(false);
                    button.removeAttribute("disabled");
                });  
            } 
        }
    }
    
    // Zmiana koloru

    function showMarkerColorChange(){
        setModal({...modal, show: true, mobile_marker_menu: false, marker_color: true});
        setAxiosError(null);
        setColor({...color, color: activeMarker.color});
    }

    function changeMarkerColor(){

        let old_color = activeMarker.color;
        let new_color = color.color;

        if(old_color !== new_color){
            let marker_id = activeMarker.id;
            let type = activeMarker.type;
            let map_name = map.project_name;
            let request_type = 'change color';
            setModalSpin(true);
            
            Axios.post('classes/marker.php', { marker_id, map_name, type, new_color, request_type }, { timeout: 5000}).then((response)=>{
                if (Array.isArray(response.data.markers)){
                    setMarkers([]);
                    setActiveMarker({...activeMarker, color: new_color});
                    setUpdatedMarkers(response.data.markers);
                    setAxiosError(null);
                    closeModal();
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setAxiosError("Przekroczono czas połączenia.");
                } else {
                    setAxiosError("Błąd sieciowy, proszę spróbować później.");
                }
                setModalSpin(false);
            });  
        } else {
            closeModal();
        }
    }

    // Usuwanie markera

    function showMarkerDelete(){
        setModal({...modal, show: true, mobile_marker_menu: false, marker_delete: true});
        setAxiosError(null);
    }

    function deleteMarker(){
        let marker_id = activeMarker.id;
        let type = activeMarker.type;
        let map_name = map.project_name;
        let request_type = 'delete marker';
        let button = document.getElementById('delete-marker-button');
        setModalSpin(true);
        button.setAttribute("disabled", "disabled");
        
        Axios.post('classes/marker.php', { marker_id, map_name, type, request_type }, { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data.markers)){
                closeModal();
                setMarkers([]);
                setActiveMarker([]);
                setMarkerImages([]);
                setUpdatedMarkers(response.data.markers);
                setAxiosError(null);
            } else if (Array.isArray(response.data.layer)) {
                closeModal();
                setMarkers([]);
                setActiveMarker([]);
                setMarkerImages([]);
                setAxiosError(null);
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setAxiosError("Przekroczono czas połączenia.");
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
            button.removeAttribute("disabled");
        }); 
        
    }

    // Wyszukiwanie lokalizacji na mapie

    const [selectedPlace, setSelectedPlace] = useState(null);

    const handlePlaceSelected = (place) => {
        setSelectedPlace(place);
    };

    useEffect(() => {

        if(selectedPlace){
            let lat = selectedPlace.geometry.location.lat();
            let lng = selectedPlace.geometry.location.lng();
            setCenter({lat: lat, lng: lng});
            setSelectedInfoWindow(true);
        }

    }, [selectedPlace]);

    const [selectedInfoWindow, setSelectedInfoWindow] = useState(false);

    // Dodanie znalezionego miejsca jako markera

    function addSelectedPlaceMarker(){

        if(!activeLayer.id){
            setModal({...modal, show: true, message: true});
            setModalMessage('Aby dodać punkt najpierw wybierz warstwę!');
        }

        if(!activeLayer.id || !selectedPlace){
            return;
        }

        let map_name = map.project_name;

        const data = {
            layer_id: activeLayer.id,
            name: selectedPlace.name,
            description: selectedPlace.formatted_address.replace(/,\s*[A-Za-z\s]+$/, ''),
            latitude: selectedPlace.geometry.location.lat(),
            longitude: selectedPlace.geometry.location.lng()
        }

        let request_type = 'add defined marker';
            
        Axios.post('classes/marker.php', { map_name, data, request_type }, { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data.markers)){
                setSelectedInfoWindow(false);
                setMarkers([]);
                setUpdatedMarkers(response.data.markers);
                setAxiosError(null);
                let index = response.data.markers_count;
                setActiveMarker(response.data.markers[index]);
            } else {
                setAxiosError("Błąd sieciowy");
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setAxiosError("Brak połączenia");
            } else {
                setAxiosError("Błąd sieciowy");
            }
        });  

    }

    // Pozostałe

    const icons = {
        camera: 'M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z',
        deactivated: 'M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z',
        location: 'M6.25 20.5 c-2.8 -3.6 -5.55 -8.8 -6 -11.2 -0.35 -1.8 0.55 -4.15 2.45 -6.2 3.8 -4.25 11.15 -3.35 13.5 1.55 1.4 3 1.45 4.4 0.3 7.5 -0.95 2.55 -6.1 10.25 -7.4 11.05 -0.3 0.15 -1.6 -1.05 -2.85 -2.7 m5.5 -8.75 c1.75 -1.7 1.65 -3.9 -0.2 -5.8 -1.75 -1.75  -3.55 -1.85 -5.25 -0.35 -2 1.75 -2.15 3.9 -0.55 5.8 1.7 2.05 4.2 2.2 6 0.35z',
        pin: 'M9.5625 0l-1.6875 1.6875 1.6875 1.6875-3.9375 4.5h-3.9375l3.09375 3.09375-4.78125 6.339 0.576923 0.625h0.625l6.339-4.78125 3.09375 3.09375v-3.9375l4.5-3.9375 1.6875 1.6875 1.6875-1.6875-8.4375-8.4375zM7.875 9.5625l-1.125-1.125 3.9375-3.9375 1.125 1.125-3.9375 3.9375z'
    }
    
    const [hovered, setHovered] = useState({id: null, type: null});

    // ZDJĘCIA //

    // Modal

    const [markerImages, setMarkerImages] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const [imageIndex, setImageIndex] = useState();

    const [modalSlides, setModalSlides] = useState([]);

    function runModal(id){
        setImageIndex(id);
        setOpenModal(true);
    }

    useEffect(() => {
        setModalSlides(
            markerImages.map((image) => {
                return { src: image.photo };
            })
        );
    }, [markerImages]);

    // Dołączanie pojedynczych zdjęć

    function showEditPhotos(){
        setMarkerForm({...markerForm, active: 1, image: 1});
    }

    const [image, setImage] = useState('');

    const [imageDelete, setImageDelete] = useState('');

    // Wklejanie zdjęć

    const handlePaste = async (e) => {
        var item = await e.clipboardData.items[0];
        if (item.type.indexOf("image") === 0) {
            setImage(item.getAsFile());
        } else {
            setMarkerError('Wklejone dane to nie zdjęcie');
        }
    }

    // Upuszczanie zdjęć

    function allowDrop(e) {
        e.preventDefault();
    }

    function handleDrop(e) {
        let type = e.dataTransfer.files[0].type;
        var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpeg', 'image/jpg'];
        if (imageTypes.includes(type)) {
            setImage(e.dataTransfer.files[0]);
        } else {
            setMarkerError('Wybrany plik to nie zdjęcie');
        }
    }

    // Wysyłanie zdjęcia

    useEffect(() => {
        if(image){
            sendImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    function sendImage(){

        setModalMessage(null);
        setModalErrorMessage(null);

        setModal({...modal, show: true, waiting: true});

        setWaitingTitle('Wysyłanie zdjęcia');

        const data = new FormData();
        data.append('image', image);
        data.append('map_name', map.project_name);
        data.append('layer_id', activeMarker.layer_id);
        data.append('marker_id', activeMarker.id);
        data.append('type', activeMarker.type);
        data.append('request_type', 'insert photos');

        Axios.post('classes/image.php', data , {timeout: 25000}).then((response)=>{
            if(response.data === true){
                getImages();
                setImage('');
                setModalMessage('Zdjęcie wysłano poprawnie.');
            } else if(response.data === false) {
                setModalErrorMessage("Wystąpił błąd w trakcie wysyłania zdjęcia.");
            } else {
                setModalErrorMessage("Błąd sieciowy, proszę spróbować później.");
            }
            setModal({...modal, show: true, waiting: false, message: true});
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setModalErrorMessage("Przekroczono czas połączenia.");
            } else {
                setModalErrorMessage("Błąd sieciowy, proszę spróbować później.");
            }
            setModal({...modal, show: true, waiting: false, message: true});
        });
    }

    // Pobieranie listy zdjęć

    function getImages(){
        const data = new FormData();
        data.append('map_name', map.project_name);
        data.append('photo_id', activeMarker.id);
        data.append('type', activeMarker.type);
        data.append('request_type', 'get images');

        Axios.post('classes/image.php', data , { timeout: 5000}).then((response)=>{
            if(Array.isArray(response.data)){
                setMarkerImages(response.data);
            } else if(response.data === 0){
                setMarkerImages([]);
            } else {
                setMarkerError("Błąd sieciowy, proszę spróbować później.");
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setMarkerError("Przekroczono czas połączenia.");
            } else {
                setMarkerError("Błąd sieciowy, proszę spróbować później.");
            }
        });
    }

    function handleMarkerGallery(e, index){
        if(e.target.src === "https://test7.gniezno.pl/google.jpg"){
            window.open(markerImages[index].photo, '_blank');
        } else {
            runModal(index);
        }
    }

    // Usuwanie zdjęć

    function showMarkerImageDelete(photo_id){
        setAxiosError('');
        setModal({...modal, show: true, image_delete: true});
        setImageDelete(photo_id);
    }

    function deleteImage(){

        setModalSpin(true);
        const data = new FormData();
        data.append('map_name', map.project_name);
        data.append('id', imageDelete);
        data.append('photo_id', activeMarker.id);
        data.append('type', activeMarker.type);
        data.append('request_type', 'delete image');

        Axios.post('classes/image.php', data , { timeout: 5000}).then((response)=>{
            if (Array.isArray(response.data)){
                setMarkerImages(response.data);
                setAxiosError('');
                closeModal();
            } else if (response.data === 0) {
                setMarkerImages([]);
                setAxiosError('');
                closeModal();
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setAxiosError("Przekroczono czas połączenia.");
            } else {
                setAxiosError("Błąd sieciowy, proszę spróbować później.");
            }
            setModalSpin(false);
        }); 
    }

    // Dodawanie zdjęcia z telefonu

    const [selectedFile, setSelectedFile] = useState();

    const [preview, setPreview] = useState();
    
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);
    
    const handlePhoto = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return
        }
        closeModal();
        setSelectedFile(e.target.files[0]);
    }
    
    function sendCameraImage(){
        setImage(selectedFile);
        clearMarkerForm();
        setSelectedFile(undefined);
    }
    
    function removeCameraImage(){
        setSelectedFile();
    }

    // Wysyłanie wielu zdjęć
    
    const [uploadedFiles, setUploadedFiles] = useState([]);
    
    const handlePhotos = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setUploadedFiles([]);
            return
        }
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles);
    }
    
    const handleUploadFiles = files => {
        setMarkerError(null);
        const uploaded = [...uploadedFiles];
        files.forEach( file => uploaded.push(file));
        setUploadedFiles(uploaded);
    }

    function sendCameraImages(){

        setModal({...modal, show: true, waiting: true});

        setWaitingTitle('Wysyłanie zdjęć');
    
        const data = new FormData();
        uploadedFiles.forEach((file, index )=> data.append('file'+[index], file));
        data.append('map_name', map.project_name);
        data.append('layer_id', activeMarker.layer_id);
        data.append('marker_id', activeMarker.id);
        data.append('type', activeMarker.type);
        data.append('request_type', 'insert photos');
    
        Axios.post('classes/image.php', data , { timeout: 45000}).then((response)=>{
            if(response.data === true){
                getImages();
                setUploadedFiles([]);
                setMarkerError(null);
            } else {
                setMarkerError("Błąd sieciowy, proszę spróbować później.");
            }
            closeModal();
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setMarkerError("Przekroczono czas połączenia.");
            } else {
                setMarkerError("Błąd sieciowy, proszę spróbować później.");
            }
            closeModal();
        });

    }
    
    function removeCameraImages(){
        setUploadedFiles([]);
    }

    // Pobieranie zdjęć ze zmianą nazwy

    const [archiveMessage, setArchiveMessage] = useState(null);

    function downloadPhotos(){

        let button = document.getElementById('download-archive');
        button.setAttribute("disabled", "disabled");

        const data = new FormData();
        data.append('map_name', map.project_name);
        data.append('photo_id', activeMarker.id);
        data.append('type', activeMarker.type);
        data.append('request_type', 'download photos');

        setModal({...modal, show: true, waiting: true})

        Axios.post('classes/image.php', data , { timeout: 45000}).then((response)=>{
            if(response.data.download){
                downloadFile(response.data.download, response.data.filename);
                //removeArchive(response.data.filename);
            } else if(response.data === false) {
                setArchiveMessage("Nie udało się pobrać plików.");
            } else {
                setArchiveMessage("Błąd sieciowy, proszę spróbować później.");
            }
            closeModal();
            button.removeAttribute("disabled");
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setArchiveMessage("Przekroczono czas połączenia.");
            } else {
                setArchiveMessage("Błąd sieciowy, proszę spróbować później.");
            }
            closeModal();
            button.removeAttribute("disabled");
        });
    }

    // Sortowanie

    const [currentSorting, setCurrentSorting] = useState('modified');

    useEffect(() => {
        if(updatedMarkers.length > 0){
            setMarkers([]);
            setMarkers(updatedMarkers);

            
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedMarkers]);

    function onSortChange(e){

        const sortProperty = e.target.value;

        setCurrentSorting(sortProperty);

        const sorted = SortUpdate(markers, sortProperty);

        setMarkers([]);

        setUpdatedMarkers(sorted);

    }

    function SortUpdate(markersArray, sortProperty){

        let sorted;

        switch(sortProperty){

            case "modified":

                sorted = [...markersArray].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));

                return sorted;

            case "marker_name":

                sorted = [...markersArray].sort((a, b) => {

                    const propA = a[sortProperty];
                    const propB = b[sortProperty];
                  
                    const isNumericA = /^\d/.test(propA);
                    const isNumericB = /^\d/.test(propB);
                  
                    if (isNumericA && isNumericB) {
                      return parseFloat(propA) - parseFloat(propB);
                    }
                  
                    if (isNumericA && !isNumericB) {
                      return -1; 
                    } else if (!isNumericA && isNumericB) {
                      return 1;
                    }
    
                    return propA.localeCompare(propB);
    
                });

                return sorted;

            case "camera_number":

                sorted = [...markersArray].sort((a, b) => a[sortProperty] > b[sortProperty] ? 1 : -1);

                return sorted;
           
            default:
                //
        }
        
    }

    /*


    function SortUpdate(markersArray, sortProperty){

        setMarkers([]);

        let sorted;

        switch(sortProperty){

            case "modified":

                sorted = [...markersArray].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));

                setUpdatedMarkers(sorted);

                break;

            case "marker_name":

                sorted = [...markersArray].sort((a, b) => {

                    const propA = a[sortProperty];
                    const propB = b[sortProperty];
                  
                    const isNumericA = /^\d/.test(propA);
                    const isNumericB = /^\d/.test(propB);
                  
                    if (isNumericA && isNumericB) {
                      return parseFloat(propA) - parseFloat(propB);
                    }
                  
                    if (isNumericA && !isNumericB) {
                      return -1; 
                    } else if (!isNumericA && isNumericB) {
                      return 1;
                    }
    
                    return propA.localeCompare(propB);
    
                });

                setUpdatedMarkers(sorted);

                break;

            case "camera_number":

                sorted = [...markersArray].sort((a, b) => a[sortProperty] > b[sortProperty] ? 1 : -1);

                setUpdatedMarkers(sorted);

                break;
           
            default:
                //
        }
        
    }





    function SortUpdate(){

        let sortProperty = currentSorting;
        if (sortProperty === 'marker_name'){

            //const sorted = [...updatedMarkers].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
            //setMarkers(sorted);

            const sorted = [...updatedMarkers].sort((a, b) => {

                const propA = a[sortProperty];
                const propB = b[sortProperty];
              
                const isNumericA = /^\d/.test(propA);
                const isNumericB = /^\d/.test(propB);
              
                if (isNumericA && isNumericB) {
                  return parseFloat(propA) - parseFloat(propB);
                }
              
                if (isNumericA && !isNumericB) {
                  return -1; 
                } else if (!isNumericA && isNumericB) {
                  return 1;
                }

                return propA.localeCompare(propB);

            });

            setMarkers(sorted);
            
        } else if (sortProperty === 'camera_number'){

            const sorted = [...updatedMarkers].sort((a, b) => a[sortProperty] > b[sortProperty] ? 1 : -1);
            setMarkers(sorted);

        }
    }

    */

    

    function showModalBlock(){
        setModal({...modal, show: true, mobile_marker_menu: true})
        document.body.style.overflow = 'hidden';
    }

    // Aktualizowanie danych z bazy danych

    const latestApp = useRef();
    const latestMap = useRef();
    const latestLayers = useRef();
    const latestMarkers = useRef();

    useEffect(() => {

        latestApp.current = appLayer;

    }, [appLayer]);
    
    useEffect(() => {


        if(map.id !== ''){
            latestMap.current = map;
        }

    }, [map]);

    useEffect(() => {

        latestLayers.current = layers;

    }, [layers]);

    useEffect(() => {
        
        latestMarkers.current = markers;

    }, [markers]);

    const [newLayers, setNewLayers] = useState([]);

    const [newMarkers, setNewMarkers] = useState([]);

    useEffect(() => {

        const h = setInterval(() => {

            if(latestApp.current === 300 && latestMap.current){
                getUpdatedData();
            }

            function getUpdatedData(){

                let map_name = latestMap.current.project_name;
                let request_type = 'get layers';

                Axios.post('classes/layer.php', { map_name, request_type }, { timeout: 4000 }).then((response)=>{
                    if (Array.isArray(response.data.layer)){
                        setLayerError(null);
                        setNewLayers(response.data.layer);
                        if(response.data.markers){
                            setNewMarkers(response.data.markers);
                        }
                    } else if (response.data.layer === 0){
                        setLayerError(null);
                        setLayers([]);
                        setActiveLayer({id: '', name: ''});
                        setMarkers([]);
                        setActiveMarker([]);
                    } 
                }).catch((error) => {
                    console.log(error);
                });
                
            }

        }, 10000);

        return () => { clearInterval(h) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const arraysAreEqual = (array1, array2) => {
        if (array1.length !== array2.length) {
          return false;
        }
        for (let i = 0; i < array1.length; i++) {
          if (array1[i].id !== array2[i].id) {
            return false;
          }
        }
        return true;
    };

    useEffect(() => {

        if(newLayers.length > 0){

            const arraysAreEqualCheck = arraysAreEqual(layers, newLayers);

            if(!arraysAreEqualCheck){
                const exists = newLayers.some(obj => obj.id === activeLayer.id);
                if(!exists){
                    setActiveLayer({id: '', name: ''});
                }
                setLayers(newLayers);
            } 
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newLayers]);  

    useEffect(() => {

        if(newMarkers.length > 0){

            let sorted;

            if(currentSorting !== 'modified'){
                sorted = SortUpdate(newMarkers, currentSorting);
            } else {
                sorted = newMarkers;
            }

            const arraysAreEqualCheck = arraysAreEqual(markers, sorted);

            if(!arraysAreEqualCheck){
                const exists = newMarkers.some(obj => obj.id === activeMarker.id && obj.type === activeMarker.type);
                if(!exists){
                    setActiveMarker([]);
                }
                setUpdatedMarkers(sorted);
            } 
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMarkers]); 

    // Przeliczanie różnicy czasu malina - kamera

    function calculateTimeDifference(startTime, endTime){
        const start = new Date(startTime);
        const end = new Date(endTime);

        const differenceInMilliseconds = end - start;
        const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
        
        const hours = Math.floor(differenceInSeconds / 3600);
        const minutes = Math.floor((differenceInSeconds % 3600) / 60);
        const seconds = differenceInSeconds % 60;

        return `${!isNaN(hours) ? hours : '-'}:${!isNaN(minutes) ? (minutes < 10 ? '0'+minutes : minutes) : '-'}:${!isNaN(seconds) ? (seconds < 10 ? '0'+seconds : seconds) : '-'}`;

    }

    // ------------------------------------------------

    // Panel administracyjny - 400

    const [message, setMessage] = useState('pobieranie listy użytkowników');

    const [users, setUsers] =  useState({});

    const [deleteId, setDeleteId] =  useState();

    const [adminFormData, setAdminFormData] = useState(
        {
            username: '',
            password: '',
            role: ''
        }
    );

    function adminFormChange(event){
        const {name, value} = event.target;
        setAdminFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    const [adminModal, setAdminModal] = useState(
        {
            show: false,
            create: false,
            delete: false
        }
    );

    function closeAdminModal(){
        setAdminModal({show: false, create: false, delete: false});
    }

    function getUsers(){
        Axios.get('classes/user.php', { timeout: 5000 }).then(function(response){
            if(response.data){
                setUsers(response.data);
                setMessage('');
            } else {
                setMessage('Błąd sieciowy, proszę spróbować później.');
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setMessage('Przekroczono czas połączenia.');
            } else {
                setMessage('Błąd sieciowy, proszę spróbować później.');
            }
        });
    }

    function createUser(){
        if(!adminFormData.username || !adminFormData.password || !adminFormData.role){
            setMessage('Proszę wypełnić brakujące pola');
        } else {
            let request_type = 'add user';
            let data = adminFormData;

            Axios.post('classes/user.php', { request_type, data }, { timeout: 5000}).then((response)=>{
                if(Array.isArray(response.data)){
                    setUsers(response.data);
                    closeAdminModal();
                } else if(response.data === 'exist'){
                    setMessage("Użytkownik już istnieje.");
                } else {
                    setMessage("Błąd sieciowy, proszę spróbować później.");
                }
            }).catch((error) => {
                if(error.code === 'ECONNABORTED'){
                    setMessage("Przekroczono czas połączenia.");
                } else {
                    setMessage("Błąd sieciowy, proszę spróbować później.");
                }
            }); 

        }
    }

    function showDelete(id){
        setAdminModal({show: true, delete: true});
        setDeleteId(id);
    }

    function removeUser(){
        let request_type = 'remove user';
        let id = deleteId;

        Axios.post('classes/user.php', { request_type, id }, { timeout: 5000}).then((response)=>{
            if(Array.isArray(response.data)){
                setUsers(response.data);
                closeAdminModal();
            } else {
                setMessage("Błąd sieciowy, proszę spróbować później.");
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setMessage("Przekroczono czas połączenia.");
            } else {
                setMessage("Błąd sieciowy, proszę spróbować później.");
            }
        }); 
    }

    // Inne

    const [ libraries ] = useState(['places']);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyD3e0uTrKOHd9zachVjTip3qJQpr7Zt1Z4',
        libraries
    });

    useEffect(() => {
        triggerMapCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Czynności przy zmianie widoku

    useEffect(() => {

        if(appLayer === 100 || appLayer === 300){
            triggerMapCheck();
        }

        if(appLayer === 200){
            if(map.project_name){
                setDisassemblyProject(map.project_name);
            } else {
                getProjects();
            };
        }

        if(appLayer === 400){
            getUsers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLayer]);

    function triggerMapCheck(){
        if(map.id === ''){
            db.map.toArray().then(function(result){
                if(result.length > 0){
                    let saved = result[0];
                    getProjects(saved);
                } else {
                    openMapMenu();
                }         
            });
        }
    }

/*

    function changeMainView(name){

        setRaspberryMessage([]);
        setRaspberryErrorMessage('');

    }

    {currentView.photo && 
        <div className="camera-panel-overlay-wrapper">
            <div className="camera-panel-overlay-inside">
                <p className="app1-text">Zapis obrazu z kamery</p>
                {addCameraFormData.add_photo === '' && addCameraFormData.add_photo_error === '' && <>
                    <p className="camera-panel-info-text">Trwa pobieranie obrazu</p>
                    <div className="spinner-container">
                        <Spinner/>
                    </div>
                    <button className="cam-accept-button" onClick={() => changeMainView('')}>Powróć</button>
                </>}
            {addCameraFormData.add_photo !== '' && (
                <img id="add_camera_image_preview" src={`data:image/jpeg;base64,${addCameraFormData.add_photo}`} alt="obraz z kamery" />
            )}
            {addCameraFormData.add_photo_error !== '' && 
                <>
                    <p className="camera-panel-warning-message">{addCameraFormData.add_photo_error}</p>
                    <button className="cam-accept-button" onClick={() => retakeCameraPhoto()}>Ponów</button>
                    <button className="cam-accept-button" onClick={() => changeMainView('')}>Powróć</button>
                </>
            }
            {addCameraFormData.add_photo !== '' && (
                <>
                    <button className="cam-accept-button" onClick={() => changeMainView('')}>Zatwierdź</button>
                    <button className="cam-accept-button" onClick={() => retakeCameraPhoto()}>Ponów</button>
                </>
            )}
            </div>
        </div>
    }   

    function retakeCameraPhoto(){
        setAddCameraFormData(prevFormData => {
            return {
                ...prevFormData,
                add_photo: '',
                add_photo_error: ''
            }
        });
        takeCameraPhoto();
    }

    function takeCameraPhoto(){

        let map_name = map.project_name;
        let request_type = 'get camera photo';
        let camera_ip = IPcam;

        Axios.post('classes/camera.php', { map_name, request_type, camera_ip }, { timeout: 5000}).then((response)=>{
            if (response.data.image){
                setAddCameraFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        add_photo: response.data.image
                    }
                });
            } else if(response.data.error) {
                setAddCameraFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        add_photo_error: response.data.error
                    }
                });
            }
        }).catch((error) => {
            if(error.code === 'ECONNABORTED'){
                setMarkerError("Przekroczono czas połączenia.");
            } else {
                setMarkerError("Błąd sieciowy, proszę spróbować później.");
            }
            
        }); 
    }

*/

    // Demontaż kamer - 200

    // Wybór mapy

    const [disassemblyProject, setDisassemblyProject] = useState();

    function onDisassemblyProjectChange(e) {

        const value = e.target.value;

        setDisassemblyPoint('inny');

        setDisassemblyProject(value);

    }

    

    // Pobranie listy punktów i kamer do wybranej mapy

    useEffect(() => {

        if(disassemblyProject){

            getPoints(disassemblyProject);

            getCameras(disassemblyProject);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disassemblyProject]);

    // Wybór punktu

    const [disassemblyPoint, setDisassemblyPoint] = useState('inny');

    function onDisassemblyPointChange(e) {

        const value = e.target.value;

        setDisassemblyPoint(value);

    }

    // Aktywowanie domyślnego(pierwszego) punktu

    useEffect(() => {

        if(cameraPoints.length > 0){

            setDisassemblyPoint(cameraPoints[0].marker_name);

        }

    }, [cameraPoints]);

    /*

    useEffect(() => {

        if(cameraPoints.length > 0 && disassemblyPoint === 'inny'){

            setDisassemblyPoint(cameraPoints[0].marker_name);

        }

    }, [cameraPoints, disassemblyPoint]);

    */

    // Pobranie listy kamer

    const [cameras, setCameras] = useState([]);

    function getCameras(map_name){

        let request_type = 'get cameras';

        Axios.post('classes/camera.php', { map_name, request_type }, { timeout: 3000}).then((response)=>{
            if(Array.isArray(response.data)){
                setCameras(response.data);
            } else if(response.data === 0){
                setCameras([]);
            }
        }).catch((error) => {
            console.log(error);
        }); 

    }

    // Wyodrębnienie listy kamer do aktywnego punktu

    const [disassemblyCameras, setDisassemblyCameras] = useState([]);

    useEffect(() => {

        if(cameras.length > 0 && disassemblyPoint){

            const filtered = cameras.filter(item => item['point_name'] === disassemblyPoint);

            setDisassemblyCameras(filtered)

        } else if(cameras.length === 0){

            setDisassemblyCameras([]);

        }

    }, [cameras, disassemblyPoint]);

    const [grouppedDisassemblyCameras, setGrouppedDisassemblyCameras] = useState([]);

    useEffect(() => {

        if(disassemblyCameras.length > 0){

            disassemblyCameras.forEach(item => {

                const number = parseInt(item.platform.match(/\d+/)[0]);

                item.platform_number = number;

            });

            //const groupedItems = disassemblyCameras.reduce((ob, item) => ({...ob, [item.platform_number]: [...ob[item.platform_number] ?? [], item]}), {})

            const groupedItems = Object.values(disassemblyCameras.reduce((ob, item) => {
                const platformNumber = item.platform_number;
                if (!ob[platformNumber]) {
                    ob[platformNumber] = [];
                }
                ob[platformNumber].push(item);
                ob[platformNumber].sort((a, b) => a.camera_number - b.camera_number);
                return ob;
            }, {}));

            setGrouppedDisassemblyCameras(groupedItems)

        } else {

            setGrouppedDisassemblyCameras([]);

        }

    }, [disassemblyCameras]);

    const [cameraToDisassembly, setCameraToDisassembly] = useState(null);

    const [cameraIPDisassembly, setCameraIPDisassembly] = useState(null);

    const [disassemblyImages, setDisassemblyImages] = useState([]);

    function showDeactivateCamera(id, status, number){

        if(status === 'zamontowana'){

            setCameraIPDisassembly(number);
            setCameraToDisassembly(id);
            changeCurrentView('deactivate');
            getDisassembledImages(id);

        } else {

            return;

        }

    }

    function getDisassembledImages(id){

        const data = new FormData();
        data.append('map_name', disassemblyProject);
        data.append('photo_id', id);
        data.append('type', 'camera');
        data.append('request_type', 'get images');

        Axios.post('classes/image.php', data , { timeout: 3000}).then((response)=>{
            if(Array.isArray(response.data)){
                setDisassemblyImages(response.data);
            } else if(response.data === 0){
                setDisassemblyImages([]);
            } 
        }).catch((error) => {
            console.log(error);
        });

    }

    const [disassemblyModalSlides, setDisassemblyModalSlides] = useState([]);

    useEffect(() => {

        setDisassemblyModalSlides(
            disassemblyImages.map((image) => {
                return { src: image.photo };
            })
        );

    }, [disassemblyImages]);

    function disassemblyCamera(){

        clearCurrentView();
        setModal({...modal, show: true, disassembly_info: true});
        setCameraMessage(null);
        setCameraWarning(null);

        let map_name = disassemblyProject;
        let id = cameraToDisassembly;
        let request_type = 'disassembly camera';

        Axios.post('classes/camera.php', { map_name, id, request_type }, { timeout: 3000}).then((response)=>{
            if(response.data === true){
                setCameraMessage('Proces zakończony powodzeniem');
                updateStatus(id, 'zdemontowana');
            } else if(response.data === false){
                setCameraWarning("Brak połączenia z bazą danych. Kamera zostanie zdemontowana automatycznie gdy połączenie zostanie przywrócone.");
                updateStatus(id, 'zdemontowana');
                saveDisassembledDB(id, map_name);
            } else if(response.data.message){
                setCameraWarning(response.data.message);
                updateStatus(id, 'błąd');
            } else {
                setCameraWarning("Wystąpił błąd połączenia. Kamera zostanie zdemontowana automatycznie gdy połączenie zostanie przywrócone.");
                updateStatus(id, 'zdemontowana');
                saveDisassembledDB(id, map_name);
            }
        }).catch((error) => {
            setCameraWarning("Wystąpił błąd połączenia. Kamera zostanie zdemontowana automatycznie gdy połączenie zostanie przywrócone.")
            updateStatus(id, 'zdemontowana');
            saveDisassembledDB(id, map_name);
            console.log(error);
        }); 

    }

    function saveDisassembledDB(id, map_name){

        const put = {id: id, map_name: map_name}

        db.disassembly.put(put);

    }

    useEffect(() => {

        const h = setInterval(() => {

            db.disassembly.toArray().then(function(result){
                if(result.length > 0){
                    sendDisassemblyCamerasFromDB(result);
                }       
            });

            function sendDisassemblyCamerasFromDB(data){
                
                let request_type = 'disassembly camerasDB';

                Axios.post('classes/camera.php', { data, request_type }, { timeout: 4000 }).then((response)=>{
                    if(Array.isArray(response.data)){
                        let indexes = response.data;
                        db.disassembly.where('index').anyOf(indexes).delete();
                    }
                }).catch((error) => {
                    console.log(error);
                });
                
            }

        }, 5000);

        return () => { clearInterval(h) }
        
    }, []);

    const updateStatus = (id, status) => {

        const updatedData = grouppedDisassemblyCameras.map(subArray =>
          subArray.map(item => {
            if (item.id === id) {
              return { ...item, camera_status: status};
            }
            return item;
          })
        );

        setGrouppedDisassemblyCameras(updatedData);

    }

    const getClassNames = (camera_status) => {

        if(camera_status === 'zamontowana'){

          return '';

        } else if(camera_status === 'zdemontowana'){

          return 'camera-disassembly-button-disabled';

        } else {

          return 'camera-disassembly-button-error';

        }

    }

    return (
        <>
            <div id="header-outer-wrapper">
                <header>
                    <img src={Logo} alt='logo' className="logo"/>
                </header>
                <div id="menu-large-screen">
                    <div id="admin-menu-wrapper">
                        {user.role !== 'read' && window.location.hostname !== 'kamery.rubika.pl' && <button className={appLayer === 100 ? "admin-menu-item admin-menu-active" : "admin-menu-item"} onClick={()=>setAppLayer(100)}>Montaż kamer</button>}
                        {user.role !== 'read' && <button className={appLayer === 200 ? "admin-menu-item admin-menu-active" : "admin-menu-item"} onClick={()=>setAppLayer(200)}>Demontaż kamer</button>}
                        {user.role !== 'read' && <button className={appLayer === 300 ? "admin-menu-item admin-menu-active" : "admin-menu-item"} onClick={()=>setAppLayer(300)}>Mapa Google</button>}
                        {user.role === 'admin' && <button className={appLayer === 400 ? "admin-menu-item admin-menu-active" : "admin-menu-item"} onClick={()=>setAppLayer(400)}>Użytkownicy</button>}
                        <button className="admin-menu-item" onClick={logout}>Wyloguj się<img id="logout-img" src={LogoutImg} alt='logout'/></button>
                    </div>
                </div>
                <div id="menu-small-screen">
                    {user.role !== 'read' && window.location.hostname !== 'kamery.rubika.pl' && <div className={appLayer === 100 ? "mobile-icon-outer-wrapper mobile-icon-outer-wrapper-active" : "mobile-icon-outer-wrapper"} onClick={()=>setAppLayer(100)}><MenuIcon1/></div>}
                    {user.role !== 'read' && <div className={appLayer === 200 ? "mobile-icon-outer-wrapper mobile-icon-outer-wrapper-active" : "mobile-icon-outer-wrapper"} onClick={()=>setAppLayer(200)}><MenuIcon2/></div>}
                    {user.role !== 'read' && <div className={appLayer === 300 ? "mobile-icon-outer-wrapper mobile-icon-outer-wrapper-active" : "mobile-icon-outer-wrapper"} onClick={()=>setAppLayer(300)}><MenuIcon3/></div>}
                    {user.role === 'admin' && <div className={appLayer === 400 ? "mobile-icon-outer-wrapper mobile-icon-outer-wrapper-active" : "mobile-icon-outer-wrapper"} onClick={()=>setAppLayer(400)}><MenuIcon4/></div>}
                    <div className="mobile-icon-outer-wrapper"><MenuIcon5 onClick={logout}/></div>
                </div>
            </div>



            {/* Panel konfiguracji maliny */}
            {appLayer === 100 && 
            <div id="app1-outer-container">
                {map.id === '' && 
                    <div className="camera-panel-top-wrapper">
                        <button className="camera-panel-open-button" onClick={() => openMapMenu()}>Otwórz mapę</button>
                    </div>
                }
                {map.id !== '' && <div id="app1-main-container">
                    <div className="camera-panel-top-wrapper">
                        <p id="camera-panel-info-map-name" className="camera-panel-info-text">Otwarta mapa - {map.project_name}</p>
                        <button className="camera-panel-open-button camera-panel-open-button-smaller" onClick={() => setModal({...modal, show: true, assembly: true})}>Opcje</button>
                        <p id="app1-datetime">{day}, {time}</p>
                    </div>
                    <div className="camera-panel-info-warning-wrapper">
                        {IPcam && <p className="app1-text camera-info">Programujesz kamerę IP: {IPcam}</p>}
                        {/*schedule && 
                            <p className="app1-text schedule-info">Wybrany harmonogram: 
                                {schedule === '1' && <span> cały tydzień</span>}
                                {schedule === '2' && <span> wtorek - czwartek</span>}
                                {schedule === '3' && <span> wtorek</span>}
                                {schedule === '4' && <span> środa</span>}
                                {schedule === '5' && <span> czwartek</span>}
                            </p>
                        */}
                    </div>
                    <div id="app1-info-container">
                        {!IPcam && <button className="app1-main-button" onClick={() => getCameraIP()}>Znajdź kamerę</button>}
                        {IPcam && !cameraPrepare && <button className="app1-main-button" onClick={() => programateCamera()}>Zaprogramuj</button>}
                        {cameraPrepare && <button className="app1-main-button" onClick={() => showCameraVideoPreview()}>Widok</button>}
                        {cameraPrepare && clickedView && !addCameraFormData.add_point_name && <button className="app1-main-button" onClick={() => showPointsChoice()}>Wybierz punkt</button>}
                        {cameraPrepare && clickedView && addCameraFormData.add_point_name && !addCameraFormData.add_platform_number && <button className="app1-main-button" onClick={() => changeCurrentView('platform_number')}>Wybierz peron</button>}
                    </div>
                        {cameraPrepare && clickedView && addCameraFormData.add_point_name && addCameraFormData.add_platform_number && <div className="camera-panel-bottom-wrapper">
                            <button id="add-camera-button" className="cam-accept-button cam-accept-button-add" onClick={() => addAssembledCamera()}>Dodaj kamerę</button>
                            {/*addCameraFormError !== '' && <>
                                <p className="camera-panel-warning-message">{addCameraFormError}</p>
                                <p id="camera-panel-warning-info" className="camera-panel-warning-message">Spróbuj dodać kamerę ponownie. Jeśli problem się powtarza wyczyść dane za pomocą poniższego przycisku i rozpocznij dodawanie kolejnej kamery.</p>
                                <button className="cam-cancel-button" onClick={() => clearCameraAdd()}>Wyczyść dane</button>
                            </>
                            */}
                        </div>
                        }
                </div>}

                {currentView.camera_submit &&
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wpisz nr IP kamery:</p>
                            <input 
                                id="ip_cam"
                                type="number"
                                name="ip_cam"
                                min={1}
                                max={255}
                                value={raspberryForm.ip_cam}
                                onChange={changeRaspberryForm}
                            />
                            {raspberryErrorMessage && <p className="app1-error-message">{raspberryErrorMessage}</p>}
                            <button className="cam-accept-button" onClick={setIP}>Zatwierdź</button>
                            <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                        </div>
                    </div>
                }
                {currentView.camera_list && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            {raspberryMessage.length === 0 && raspberryErrorMessage === '' && 
                                <div>
                                    <p className="form-label-message">Proszę czekać, trwa pobieranie danych</p>
                                    <div className="spinner-container">
                                        <Spinner/>
                                    </div>
                                </div>
                            }
                            {raspberryMessage.length > 0 && <p className="app1-text">
                                {raspberryMessage.map((text, index) => (
                                    <span className='raspberry-message-span' key={index}>{text}</span>
                                ))}
                            </p>}
                            {raspberryErrorMessage && <p className="app1-error-message">{raspberryErrorMessage}</p>}
                            <button className="cam-accept-button" onClick={() => clearCurrentView()}>Powróć</button>
                        </div>
                    </div>
                }
                {(currentView.time_check || currentView.time_update || currentView.record_on || currentView.record_off || currentView.prepare || currentView.sd_check || currentView.modem || currentView.wifi_1 || currentView.wifi_7 || currentView.synch_time)&& 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            {raspberryMessage.length === 0 && raspberryErrorMessage === '' && 
                                <div>
                                    <p className="form-label-message">Proszę czekać, trwa pobieranie danych</p>
                                    <div className="spinner-container">
                                        <Spinner/>
                                    </div>
                                </div>
                            }
                            {raspberryMessage.length > 0 && <p className="app1-text">
                                {raspberryMessage.map((text, index) => (
                                    <span className='raspberry-message-span' key={index}>{text}</span>
                                ))}
                            </p>}
                            {raspberryErrorMessage && <p className="app1-error-message">{raspberryErrorMessage}</p>}
                            <button className="cam-accept-button" onClick={() => clearCurrentView()}>Powróć</button>
                        </div>
                    </div>
                }
                {currentView.record_schedule && <>
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wybierz harmonogram</p>
                            <div className="camera-panel-buttons-selection-wrapper camera-panel-buttons-selection-wrapper-column">
                                <button className={raspberryForm.schedule === '1' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptScheduleSelection('1')}>Cały tydzień</button>
                                <button className={raspberryForm.schedule === '2' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptScheduleSelection('2')}>Wtorek - czwartek</button>
                                <button className={raspberryForm.schedule === '3' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptScheduleSelection('3')}>Wtorek</button>
                                <button className={raspberryForm.schedule === '4' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptScheduleSelection('4')}>Środa</button>
                                <button className={raspberryForm.schedule === '5' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptScheduleSelection('5')}>Czwartek</button>
                            </div>   
                            <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                        </div>
                    </div>
                </>}
                {currentView.point_name && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            {cameraPointsUpdate && 
                                <div>
                                    <p className="form-label-message">Proszę czekać, trwa pobieranie danych</p>
                                    <div className="spinner-container">
                                        <Spinner/>
                                    </div>
                                </div>
                            }
                            {!cameraPointsUpdate &&
                                <>
                                    <p className="app1-text">Wybierz punkt montażu</p>
                                    <div className="camera-panel-buttons-selection-wrapper">
                                        {cameraPoints.map((point, index) => (
                                            <button key={index} className={addCameraFormData.add_point_name === point.marker_name ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_point_name',point.marker_name)}>{point.marker_name}</button>
                                        ))}
                                        {cameraPoints.length === 0 && <button className={addCameraFormData.add_point_name === 'Inny' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_point_name','inny')}>Inny</button>}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
                {/*currentView.point_number && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wpisz numer punktu<br></br>(pozostaw puste pole aby anulować)</p>
                            <input 
                                id="add_point_number"
                                type="text"
                                name="add_point_number"
                                className="cam-form-field"
                                onChange={addCameraFormChange}        
                                value={addCameraFormData.add_point_number}
                            />
                            <button className="cam-accept-button" onClick={() => clearCurrentView()}>Zatwierdź</button>
                        </div>
                    </div>
                */}
                {currentView.mounting_point && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wybierz miejsce montażu</p>
                            <div className="camera-panel-buttons-selection-wrapper">
                                <button className={addCameraFormData.add_mounting_point === 'latarnia' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','latarnia')}>Latarnia</button>
                                <button className={addCameraFormData.add_mounting_point === 'znak drogowy' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','znak drogowy')}>Znak drogowy</button>
                                <button className={addCameraFormData.add_mounting_point === 'drzewo' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','drzewo')}>Drzewo</button>
                                <button className={addCameraFormData.add_mounting_point === 'konstrukcja dachu' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','konstrukcja dachu')}>Konstrukcja dachu</button>
                                <button className={addCameraFormData.add_mounting_point === 'sygnalizator' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','sygnalizator')}>Sygnalizator</button>
                                <button className={addCameraFormData.add_mounting_point === 'płot' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','płot')}>Płot</button>
                                <button className={addCameraFormData.add_mounting_point === 'barierka' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','barierka')}>Barierka</button>
                                <button className={addCameraFormData.add_mounting_point === 'inne' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_mounting_point','inne')}>Inne</button>
                            </div>   
                            <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                        </div>
                    </div>
                }
                {currentView.platform_number && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wybierz numer peronu</p>
                            <div className="camera-panel-buttons-selection-wrapper">
                                <div className="camera-panel-platform-detailed-wrapper">
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">1</p>
                                        <button className={addCameraFormData.add_platform_number === '1R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','1R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '1L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','1L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">2</p>
                                        <button className={addCameraFormData.add_platform_number === '2R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','2R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '2L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','2L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">3</p>
                                        <button className={addCameraFormData.add_platform_number === '3R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','3R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '3L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','3L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">4</p>
                                        <button className={addCameraFormData.add_platform_number === '4R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','4R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '4L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','4L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">5</p>
                                        <button className={addCameraFormData.add_platform_number === '5R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','5R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '5L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','5L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">6</p>
                                        <button className={addCameraFormData.add_platform_number === '6R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','6R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '6L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','6L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">7</p>
                                        <button className={addCameraFormData.add_platform_number === '7R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','7R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '7L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','7L')}>&#8592;</button>
                                    </div>
                                    <div className="camera-panel-platform-detailed-button-wrapper">
                                        <p className="camera-panel-platform-button-title">8</p>
                                        <button className={addCameraFormData.add_platform_number === '8R' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','8R')}>&#8594;</button>
                                        <button className={addCameraFormData.add_platform_number === '8L' ? "camera-panel-button-selection camera-panel-button-arrow camera-panel-button-selected" : "camera-panel-button-selection camera-panel-button-arrow"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','8L')}>&#8592;</button>
                                    </div>
                                </div>
                                {/*<button className={addCameraFormData.add_platform_number === '1' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"}  onClick={()=> acceptAddCameraButtonSelection('add_platform_number','1')}>1</button>
                                <button className={addCameraFormData.add_platform_number === '2' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','2')}>2</button>
                                <button className={addCameraFormData.add_platform_number === '3' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','3')}>3</button>
                                <button className={addCameraFormData.add_platform_number === '4' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','4')}>4</button>
                                <button className={addCameraFormData.add_platform_number === '5' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','5')}>5</button>
                                <button className={addCameraFormData.add_platform_number === '6' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','6')}>6</button>
                                <button className={addCameraFormData.add_platform_number === '7' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','7')}>7</button>
                                <button className={addCameraFormData.add_platform_number === '8' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_platform_number','8')}>8</button>*/}
                            </div>   
                            <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                        </div>
                    </div>
                }
                {currentView.lane_number && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wybierz numer toru</p>
                            <div className="camera-panel-buttons-selection-wrapper">
                                <button className={addCameraFormData.add_lane_number === '1' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','1')}>1</button>
                                <button className={addCameraFormData.add_lane_number === '2' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','2')}>2</button>
                                <button className={addCameraFormData.add_lane_number === '3' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','3')}>3</button>
                                <button className={addCameraFormData.add_lane_number === '4' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','4')}>4</button>
                                <button className={addCameraFormData.add_lane_number === '5' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','5')}>5</button>
                                <button className={addCameraFormData.add_lane_number === '6' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','6')}>6</button>
                                <button className={addCameraFormData.add_lane_number === '7' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','7')}>7</button>
                                <button className={addCameraFormData.add_lane_number === '8' ? "camera-panel-button-selection camera-panel-button-selected" : "camera-panel-button-selection"} onClick={()=> acceptAddCameraButtonSelection('add_lane_number','8')}>8</button>
                            </div>   
                            <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                        </div>
                    </div>
                }
                {currentView.comment && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <p className="app1-text">Wpisz swój komentarz:</p>
                            <textarea 
                                id="add_comment"
                                type="text"
                                name="add_comment"
                                className="cam-form-field cam-form-field-textarea"
                                onChange={addCameraFormChange}   
                                value={addCameraFormData.add_comment}
                            />
                            <button className="cam-accept-button" onClick={() => clearCurrentView()}>Zatwierdź</button>
                        </div>
                    </div>
                }

                {modal.show &&
                <div className="modal-overlay">
                    <ClickAwayListener onClickAway={closeModal}>
                        <div>
                            {/* Otwieranie mapy */}
                            {modal.open && 
                                <div id="open-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Wybierz mapę do otwarcia</h2>
                                    </div>
                                    <div className="modal-body">
                                        {projects.map((project) =>
                                        <div key={project.id} className="form-line-wrapper">
                                            <p className="project-name" onClick={() => {openMap(project.id, project.project_name, project.project_description, project.modified, project.center_lat, project.center_lng, project.zoom)}}>{project.project_name}</p>
                                        </div>
                                        )}
                                    </div>
                                    {axiosError && 
                                    <div className="modal-footer">
                                        <p className="axios-error">{axiosError}</p>
                                    </div>
                                    }
                                </div>
                            }
                            {/* Menu montera */}
                            {modal.assembly && 
                                <div className="assembly-menu-modal modal">
                                    <div className="assembly-menu-modal-close-wrapper">
                                        <button className="assembly-menu-modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                    </div>
                                    <div className="assembly-modal-body modal-body">
                                        <div className="assembly-modal-top-wrapper">
                                            {cameraPrepare && <button className="app1-sub-button" onClick={() => programateCamera()}>Zaprogramuj</button>}
                                            {cameraPrepare && clickedView && addCameraFormData.add_point_name && <button className="app1-sub-button" onClick={() => showPointsChoice()}>Wybierz punkt</button>}
                                            {cameraPrepare && clickedView && addCameraFormData.add_point_name && addCameraFormData.add_platform_number && <button className="app1-sub-button" onClick={() => changeCurrentView('platform_number')}>Wybierz peron</button>}
                                            <button className="app1-sub-button" onClick={() => changeCurrentView('updates')}>Aktualizuj</button>
                                        </div>
                                        <div className="app-main-buttons-wrapper">
                                            <button className="app1-main-button" onClick={() => changeCurrentView('camera_submit')}>Podaj IP kamery</button>
                                            <button className="app1-main-button" onClick={() => changeCurrentView('camera_list')}>Listuj kamery</button>
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('time_check')}>Sprawdź czas</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('time_update')}>Update czasu</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('record_schedule')}>Harmonogram</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('record_on')}>Nagrywanie ON</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('record_off')}>Nagrywanie OFF</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('prepare')}>Global check</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('sd_check')}>Sprawdź SD</button>}
                                            <button className="app1-main-button" onClick={() => changeCurrentView('modem')}>Połącz internet</button>
                                            <button className="app1-main-button" onClick={() => changeCurrentView('wifi_1')}>Wifi ch. 1</button>
                                            <button className="app1-main-button" onClick={() => changeCurrentView('wifi_7')}>Wifi ch. 7</button>
                                            <button className="app1-main-button" onClick={() => changeCurrentView('synch_time')}>Ustaw czas</button>
                                            {/*IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('point_number')}>Nr punktu</button>*/}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('mounting_point')}>Mocowanie</button>}
                                            {/*IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('platform_number')}>Peron</button>*/}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('lane_number')}>Tor</button>}
                                            {IPcam && <button className="app1-main-button" onClick={() => changeCurrentView('comment')}>Komentarz</button>}
                                        </div>
                                    </div>      
                                </div>
                            }
                            {/* Okienko informacyjne panelu kamer */}
                            {(modal.assembly_info || modal.assembly_add) &&
                                <div className="assembly-menu-modal modal">
                                    {!cameraMessage && !cameraWarning && 
                                        <div>
                                            {modal.assembly_info && <p className="form-label-message">Proszę czekać, trwa pobieranie danych</p>}
                                            {modal.assembly_add && <p className="form-label-message">Proszę czekać, trwa dodawanie kamery</p>}
                                            <div className="spinner-container">
                                                <Spinner/>
                                            </div>
                                        </div>
                                    }
                                    {(cameraMessage || cameraWarning) && <div>
                                        <div className="assembly-menu-modal-close-wrapper">
                                            <button className="assembly-menu-modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                        </div>
                                        <div className="assembly-modal-body modal-body camera-messages-modal-wrapper">
                                            {cameraMessage && <p className="assembly-camera-message">{cameraMessage}</p>}
                                            {cameraWarning && <p className="assembly-camera-warning-message">{cameraWarning}</p>}
                                            <button className="cam-accept-button" onClick={() => closeModal()}>OK</button>
                                        </div>
                                    </div>}      
                                </div>
                            }
                            
                        </div>
                    </ClickAwayListener>
                </div>
                }
            </div>
            }

            {/* Panel demontażu kamer */}
            {appLayer === 200 && 
            <div id="camera-disassembly-outer-container">
                <div id="camera-disassembly-top-panel-container">
                    <div className="camera-disassembly-top-panel-wrapper">
                        <p className="camera-disassembly-top-panel-title">Projekt</p>
                        <div className="camera-disassembly-select-wrapper">
                        <select
                            onChange={onDisassemblyProjectChange}
                            value={disassemblyProject}
                            className={"camera-disassembly-select"}
                            >
                            {projects.length > 0 && projects.map((project, index) => (
                                <option key={index} value={project.project_name}>{project.project_name}</option>
                            ))}
                            {projects.length === 0 && <option value='' disabled>nie znaleziono map</option>}
                        </select>
                        </div>
                    </div>
                    <div className="camera-disassembly-top-panel-wrapper">
                        <p className="camera-disassembly-top-panel-title">Punkt</p>
                        <select
                            onChange={onDisassemblyPointChange}
                            value={disassemblyPoint}
                            className={"camera-disassembly-select"}
                            >
                            {cameraPoints.length > 0 && cameraPoints.map((point, index) => (
                                <option key={index} value={point.marker_name}>{point.marker_name}</option>
                            ))}
                            {cameraPoints.length === 0 && <option value='inny'>inny</option>}
                        </select>
                    </div>
                </div>
                {grouppedDisassemblyCameras.length > 0 && grouppedDisassemblyCameras.map((group, index) => (
                    <div key={index} className="camera-disassembly-remove-buttons-container">
                        <p className="camera-disassembly-remove-platform-title">Peron {index + 1}</p>
                        
                        <div className="camera-disassembly-remove-buttons-wrapper">
                            {group.map(camera => (
                                <button key={camera.id} className={`app1-main-button camera-disassembly-button ${getClassNames(camera.camera_status)}`} onClick={() => showDeactivateCamera(camera.id, camera.camera_status, camera.camera_number)}>{camera.camera_number}</button>
                            ))}
                        </div>
                        
                    </div>
                ))}
                {currentView.deactivate && 
                    <div className="camera-panel-overlay-wrapper">
                        <div className="camera-panel-overlay-inside">
                            <div id="disassembly-camera-confirmation-buttons-wrapper" className={disassemblyImages.length > 0 ? "disassembly-camera-confirmation-buttons-wrapper-extended" : ""}>
                                <p className="app1-text">Potwierdzasz demontaż kamery IP {cameraIPDisassembly}?</p>
                                <button className="cam-accept-button" onClick={() => disassemblyCamera()}>Zatwierdź</button>
                                <button className="cam-cancel-button" onClick={() => clearCurrentView()}>Anuluj</button>
                            </div>
                            {disassemblyImages.length > 0 && 
                                <div id="disassembly-carousel-wrapper">
                                    <Carousel>
                                        {disassemblyImages.map((image, index) =>
                                            <CarouselItem key={image.id}>
                                                <div className="carousel-image-wrapper">
                                                    <img onClick={(e) => handleMarkerGallery(e, index)} onError={e => {e.currentTarget.src = Err}}  className="marker-image" referrerPolicy="no-referrer" src={image.photo} alt="marker"/>
                                                </div>
                                            </CarouselItem>
                                        )}
                                    </Carousel>
                                    <Lightbox
                                        open={openModal}
                                        index={imageIndex}
                                        close={() => setOpenModal(false)}
                                        slides={disassemblyModalSlides}
                                        plugins={[Thumbnails, Zoom]}
                                        zoom={{
                                            maxZoomPixelRatio: 10,
                                            scrollToZoom: true
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
                {modal.show &&
                <div className="modal-overlay">
                    <ClickAwayListener onClickAway={closeModal}>
                        <div>
                            {/* Okienko informacyjne panelu kamer */}
                            {modal.disassembly_info &&
                                <div className="assembly-menu-modal modal">
                                    {!cameraMessage && !cameraWarning && 
                                        <div>
                                            <p className="form-label-message">Proszę czekać</p>
                                            <div className="spinner-container">
                                                <Spinner/>
                                            </div>
                                        </div>
                                    }
                                    {(cameraMessage || cameraWarning) && <div>
                                        <div className="assembly-menu-modal-close-wrapper">
                                            <button className="assembly-menu-modal-close-button" onClick={() => closeModal()}>&#10006;</button>
                                        </div>
                                        <div className="assembly-modal-body modal-body camera-messages-modal-wrapper">
                                            {cameraMessage && <p className="assembly-camera-message">{cameraMessage}</p>}
                                            {cameraWarning && <p className="assembly-camera-warning-message">{cameraWarning}</p>}
                                            <button className="cam-accept-button" onClick={() => closeModal()}>OK</button>
                                        </div>
                                    </div>}
                                </div>
                            }
                        </div>
                    </ClickAwayListener>
                </div>
                }
            </div>
            }


            {/* Panel główny z mapą */}
            {appLayer === 300 &&
            <div id="map-outer-container">
                <div id="side-panel" className={map.id !== '' ? "side-panel-active" : ""} >
                    <div id="side-sticky-wrapper">
                        <div className="title-container">
                            <h1 id="project-title" className="menu-title"> { map.project_name ? map.project_name : "Nie wybrano mapy" }</h1>
                            <div className="button-container">
                                <button id="title-button" className="vertical-button" onClick={openMainMenu}></button>
                                {menu.main && 
                                <ClickAwayListener onClickAway={clearMainMenu}>
                                    <div className="menu-toggleable menu-toggleable-top">
                                        {user.role === 'admin' && <p className="menu-item" onClick={createMapMenu}>Nowa mapa</p>}
                                        <p className="menu-item" onClick={openMapMenu}>Otwórz mapę</p>
                                        {(map.id && user.role === 'admin') && <p className="menu-item" onClick={renameMapMenu}>Zmień nazwę</p>}
                                        {user.role === 'admin' && <p className="menu-item" onClick={importMapMenu}>Importuj mapę</p>}
                                        {(map.id && user.role === 'admin') && <p className="menu-item" onClick={exportMapMenu}>Eksportuj mapę</p>}
                                        {user.role === 'admin' && <p className="menu-item" onClick={deleteMapMenu}>Usuń mapy</p>}
                                    </div>
                                </ClickAwayListener>
                                }
                            </div>
                        </div>
                        <div className="project-additional-info">
                            { map.project_description && <p className="project-description">{map.project_description}</p>}
                            { map.modified && <p className="project-description">Utworzona: {map.modified}</p>}
                        </div>
                        {map.project_name && (user.role === 'admin' || user.role === 'user') && <div id="layer-top-container">
                            <button id="add-layer-button" onClick={addLayer}>Dodaj warstwę{buttonSpin && <span className="add-layer-spinner"><Spinner2/></span>}</button>
                            {markers[0] && <div className="layer-sort-wrapper">
                                <p id="markers-sort">Sortuj według:</p>
                                <select
                                    id={'sort-selection'}
                                    onChange={onSortChange}
                                    value={currentSorting}
                                    >
                                    <option value="modified">daty dodania</option>
                                    <option value="marker_name">nazwy punktu</option>
                                    <option value="camera_number">numeru kamery</option>
                                </select>
                            </div>}
                        </div>}
                        {layerError && <p className="layer-error-message">{layerError}</p>}
                    </div>
                    <div id="layer-container">
                        <div id="layer-ref">
                            {layers.map((layer, index) =>
                                <div key={layer.id} className={activeLayer.id === layer.layer_id ? "layer-wrapper layer-active" : "layer-wrapper"} id={'layer-wrapper-'+index} onClick={(event) => {activateLayer(event, layer.layer_id, layer.layer_name)}}>
                                    <div className="layer-top-panel">
                                        <div className="layer-top-wrapper">
                                            <div>
                                                <input onClick={toggleLayer} defaultChecked={true} type='checkbox' value={layer.layer_id} className='toggle-layer'></input>
                                            </div>
                                            <div>
                                                <span className="layer-title">{layer.layer_name}</span>
                                            </div>
                                        </div>
                                        <div className="layer-button-wrapper">
                                            <button id={"quick-layer-hide-"+layer.layer_id} className="quick-layer-hide-button" onClick={() => {hideLayer(layer.layer_id)}}>▼</button>
                                            {(user.role === 'admin' || user.role === 'user') && <button className="vertical-button" onClick={() => {openLayerMenu(layer.layer_id, index)}}></button>}
                                            {menu.layer === layer.layer_id && 
                                            <ClickAwayListener onClickAway={clearLayerMenu}>
                                                <div className={menuToggleable ? "menu-toggleable menu-toggleable-top" : "menu-toggleable menu-toggleable-bottom"}>
                                                    <p className="menu-item" onClick={() => {showRenameLayerMenu(layer.layer_id, layer.layer_name)}}>Zmień nazwę</p>
                                                    {user.role === 'admin' && <p className="menu-item" onClick={() => showLayerColorChange()}>Ustaw kolor</p>}
                                                    {user.role === 'admin' && <p className="menu-item" onClick={() => {showDeleteLayerMenu(layer.layer_id, layer.layer_name)}}>Usuń warstwę</p>}
                                                </div>
                                            </ClickAwayListener>
                                            }
                                        </div>
                                    </div>
                                    <div id={"layer-"+layer.layer_id} className="layer-bottom-wrapper">
                                        {markers.map((marker, index) =>
                                        <div key={index} className="marker-wrapper">
                                            {marker.layer_id === layer.layer_id && <p className={(activeMarker && activeMarker.id === marker.id && activeMarker.type === marker.type) || (hovered.id === marker.id && hovered.type === marker.type) ? "marker-list-item marker-active" : "marker-list-item"} onMouseOver={() => setHovered({id:marker.id, type:marker.type})} onMouseOut={() => setHovered({id: null, type: null})} onClick={() => openMarker(index)}>{marker.marker_name}</p>}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>    
                {/* Mapa */}
                <div id="main-panel">
                    <div id="main-sticky">
                        <ClickAwayListener onClickAway={clearContextMenu}>
                            <div id="map-inner-container" onClick={showContextMenu}>
                                {isLoaded &&
                                    <>
                                    <GoogleMap 
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={zoom}
                                        onClick={logClick}
                                        ref={mapElement}
                                        gestureHandling={'cooperative'}
                                    >
                                        {selectedPlace && (
                                        <>
                                            <MarkerF
                                                position={{ lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng() }}
                                                onClick={() => setSelectedInfoWindow(true)}
                                            />
                                            {selectedInfoWindow && 
                                                <InfoWindowF
                                                    position={{lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng()}}
                                                    onCloseClick={() => setSelectedInfoWindow(false)}
                                                >
                                                    <div id="marker-infowindow">
                                                        <p className="marker-bottom-form-title">{selectedPlace.name}</p>
                                                        <p className="marker-bottom-form-title">{selectedPlace.formatted_address.replace(/,\s*[A-Za-z\s]+$/, '')}</p>
                                                        {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                                        <div className="selected-buttons-wrapper">
                                                            <button onClick={() => addSelectedPlaceMarker()} className="selected-place-button"><img src={Icon13} alt="menu-icon" title={activeLayer.id ? "Dodaj znacznik" : "Najpierw wybierz warstwę"} className={!activeLayer.id ? "selected-place-button-disabled" : ""}/></button>
                                                            <button onClick={() => window.open(selectedPlace.url, "_blank")} className="selected-place-button"><img src={Icon12} alt="menu-icon" title="Otwórz w google maps"/></button>
                                                        </div>
                                                    </div>
                                                </InfoWindowF>
                                            }
                                        </>
                                        )}
                                        {markers.map((marker, index) => (
                                        <MarkerF 
                                            key={index}
                                            position={{lat: marker.latitude, lng: marker.longitude}}
                                            icon={{
                                                //path: marker.icon,
                                                path: Object.hasOwn(icons, marker.icon) ? icons[marker.icon] : marker.icon,
                                                fillColor: marker.color,
                                                fillOpacity: 1,
                                                strokeColor: '#000',
                                                strokeWeight: 1,
                                                scale: 1,
                                                anchor: marker.type === marker ? { x: 8, y: 25 } : { x: 6, y: 13 }
                                                //anchor: { x: 10, y: 5 }
                                            }}
                                            label={{
                                                text: marker.marker_name, 
                                                className: 'marker-label label'
                                            }}
                                            
                                            draggable={marker.draggable === "true" ? true : false}
                                            visible={marker.visible === "true" ? true : false}
                                            onDrag={event => markerDrag(event, marker.id)}
                                            onClick={() => openMarker(index)}
                                            onMouseOver={() => setHovered({id:marker.id, type:marker.type})}
                                            onMouseOut={() => setHovered({id: null, type: null})}
                                        >
                                        </MarkerF>
                                        ))}
                                        {draggable && (
                                        <InfoWindowF
                                            
                                            position={newCoordinates.lat ? {lat: newCoordinates.lat+(2**(22 - mapElement.current.state.map.zoom) * 0.0000078125), lng: newCoordinates.lng} : {lat: activeMarker.latitude+(2**(22 - mapElement.current.state.map.zoom) * 0.0000078125), lng: activeMarker.longitude}}
                                            //position={newCoordinates.lat ? {lat: newCoordinates.lat+(mapElement.current.state.map.zoom/2.5), lng: newCoordinates.lng} : {lat: activeMarker.latitude+(mapElement.current.state.map.zoom/100), lng: activeMarker.longitude}}
                                            onCloseClick={() => disableMarkerDragging(activeMarker.id)}
                                            
                                        >
                                            <div id="marker-dragging-infowindow">
                                                {!modalSpin && <>
                                                <p className="marker-bottom-form-title">Przeciągnij marker w inne położenie <br></br>i kliknij zmień aby zaakceptować.</p>
                                                {newCoordinates.lat &&
                                                    <div> 
                                                        <p className="marker-additional-info">Nowe położenie:</p>
                                                        <p className="marker-additional-info">({newCoordinates.lat}, {newCoordinates.lng})</p>
                                                    </div>
                                                }
                                                {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                                <div className="marker-bottom-buttons-wrapper">
                                                    <button className="marker-bottom-form-button" id="marker-drag-button" onClick={changeMarkerPosition}>Zmień</button>
                                                    <button className="marker-bottom-form-button" onClick={() => disableMarkerDragging(activeMarker.id)}>Anuluj</button>
                                                </div>
                                                </>}
                                                {modalSpin && <div>
                                                    <p className="form-label-message">Proszę czekać</p>
                                                    <div className="spinner-container">
                                                        <Spinner/>
                                                    </div>
                                                </div>}
                                            </div>
                                        </InfoWindowF>
                                        )}
                                        {userCoordinates.lat &&
                                        <MarkerF 
                                            position={{lat: userCoordinates.lat, lng: userCoordinates.lng}}
                                            icon={{
                                                path: 'M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z',
                                                fillColor: '#000',
                                                fillOpacity: 1,
                                                strokeColor: '#000',
                                                strokeWeight: 1,
                                                scale: 1
                                            }}
                                        >
                                        </MarkerF>}
                                    </GoogleMap>
                                    <div id="search-bar-wrapper"><SearchLocation onPlaceSelected={handlePlaceSelected}/></div>
                                    </>
                                }
                                <div id="add-marker-spinner-wrapper"><Spinner/></div>
                                <div id="center-map-wrapper"></div>
                                {map.id &&
                                    <div id="add-context-menu-wrapper">
                                        <p className="context-menu-item" onClick={centerMap}>Ustaw zakres mapy</p>
                                        {activeLayer.id && <p className="context-menu-item" onClick={addMarker}>Dodaj znacznik</p>}
                                    </div>
                                }
                            </div>
                        </ClickAwayListener>
                        {/* Panel markerów */}
                        {(activeLayer.id || markers[0] || activeMarker.id) && 
                        <div id="marker-panel">
                            <div id="marker-panel-top-row">
                                <div>
                                    <h4 className={activeMarker.id ? "marker-heading" : 'marker-heading marker-heading-lowered'}>{activeMarker.id ? 'Znacznik: '+ activeMarker.marker_name : 'Nie wybrano znacznika'}</h4>
                                </div>
                                <div id="marker-panel-top-inner-row">
                                    <div id="marker-panel-left-wrapper" className={(markerForm.active || markerImages.length > 0) ? "marker-panel-left-wrapper-half" : "marker-panel-left-wrapper-full"}>
                                        {activeMarker.id &&
                                        <table className="data-table">
                                            <tbody>
                                                <tr>
                                                    <th>Nazwa punktu</th>
                                                    <td className="marker-additional-info">{activeMarker.marker_name}</td>
                                                </tr>
                                                {activeMarker.marker_description && <tr>
                                                    <th>Opis punktu</th>
                                                    <td className="marker-additional-info">{activeMarker.marker_description}</td>
                                                </tr>}
                                                <tr>
                                                    <th>Współrzędne</th>
                                                    <td className="marker-additional-info">({activeMarker.latitude}, {activeMarker.longitude})</td>
                                                </tr>
                                                {user.role !== 'read' && activeMarker.type === 'camera' && <>
                                                    {activeMarker.camera_number && <tr>
                                                        <th>Numer kamery</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_number}</td>
                                                    </tr>}
                                                    {activeMarker.point_name && <tr>
                                                        <th>Nazwa punktu</th>
                                                        <td className="marker-additional-info">{activeMarker.point_name}</td>
                                                    </tr>}
                                                    {activeMarker.camera_status && <tr>
                                                        <th>Status kamery</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_status}</td>
                                                    </tr>}
                                                    {activeMarker.camera_mounting_point && <tr>
                                                        <th>Miejsce montażu</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_mounting_point}</td>
                                                    </tr>}
                                                    {activeMarker.platform && <tr>
                                                        <th>Peron</th>
                                                        <td className="marker-additional-info">{activeMarker.platform}</td>
                                                    </tr>}
                                                    {activeMarker.lane && <tr>
                                                        <th>Tor</th>
                                                        <td className="marker-additional-info">{activeMarker.lane}</td>
                                                    </tr>}
                                                    {activeMarker.camera_log_date && <tr>
                                                        <th>Data - kamera</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_log_date}</td>
                                                    </tr>}
                                                    <tr>
                                                        <th>Data - malina</th>
                                                        <td className="marker-additional-info">{activeMarker.raspberry_time}</td>
                                                    </tr>
                                                    {activeMarker.camera_log_date && <tr>
                                                        <th>Różnica</th>
                                                        <td className="marker-additional-info">{calculateTimeDifference(activeMarker.camera_log_date,activeMarker.raspberry_time)}</td>
                                                    </tr>}
                                                    <tr>
                                                        <th>Data - wpis w BD</th>
                                                        <td className="marker-additional-info">{activeMarker.modified}</td>
                                                    </tr>
                                                    {activeMarker.camera_log_schedule && <tr>
                                                        <th>Log - harmonogram</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_log_schedule.split('\n').map((line, index) => (
                                                            <span className="camera-log" key={index}>{line}</span>
                                                        ))}</td>
                                                        {/*<td className="marker-additional-info">{activeMarker.camera_log_schedule}</td>*/}
                                                    </tr>}
                                                    {activeMarker.camera_log_recording && <tr>
                                                        <th>Log - nagrywanie</th>
                                                        <td className="marker-additional-info">
                                                            {activeMarker.camera_log_recording.split('\n').map((line, index) => (
                                                                <span className="camera-log" key={index}>{line}</span>
                                                            ))}
                                                        </td>
                                                    </tr>}
                                                    {activeMarker.camera_log_memory && <tr>
                                                        <th>Log - pamięć</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_log_memory}</td>
                                                    </tr>}
                                                    {activeMarker.camera_log_other && <tr>
                                                        <th>Log - pozostałe</th>
                                                        <td className="marker-additional-info">{activeMarker.camera_log_other}</td>
                                                    </tr>}
                                                    {activeMarker.username && <tr>
                                                        <th>Dodana przez</th>
                                                        <td className="marker-additional-info">{activeMarker.username}</td>
                                                    </tr>}
                                                </>}
                                            </tbody>
                                        </table>}
                                    </div>
                                    {(markerForm.active || markerImages.length > 0 || selectedFile || uploadedFiles[0]) && 
                                        <div id="marker-panel-right-wrapper">
                                            <div id="markers-photos-wrapper">
                                                {!selectedFile && !uploadedFiles[0] && !markerForm.active && markerImages.length > 0 && 
                                                <>
                                                <Carousel>
                                                    {markerImages.map((image, index) =>
                                                        <CarouselItem key={image.id}>
                                                            <div className="carousel-image-wrapper">
                                                                {image.photo_id === activeMarker.id && <img onClick={(e) => handleMarkerGallery(e, index)} onError={e => {e.currentTarget.src = Err}}  className="marker-image" referrerPolicy="no-referrer" src={image.photo} alt="marker"/>}
                                                                {user.role === 'admin' && <img className="carousel-image-delete-button" src={ImageDeleteIcon} alt="delete-icon" onClick={() => {showMarkerImageDelete(image.id)}}/>}
                                                            </div>
                                                        </CarouselItem>
                                                    )}
                                                </Carousel>
                                                {archiveMessage && <p id='archive-message'>{archiveMessage}</p>}
                                                <Lightbox
                                                    open={openModal}
                                                    index={imageIndex}
                                                    close={() => setOpenModal(false)}
                                                    slides={modalSlides}
                                                    plugins={[Thumbnails, Zoom]}
                                                    zoom={{
                                                        maxZoomPixelRatio: 10,
                                                        scrollToZoom: true
                                                    }}
                                                />
                                                </>}
                                                {selectedFile && <div>
                                                    <img id="user-image-preview" src={preview} alt="user-input" /> 
                                                    {markerError && <p className="marker-error-message">{markerError}</p>}
                                                    <div className="marker-bottom-buttons-wrapper">
                                                        <button className="marker-bottom-form-button" onClick={sendCameraImage}>Wyślij</button>
                                                        <button className="marker-bottom-form-button" onClick={removeCameraImage}>Anuluj</button>
                                                    </div>
                                                </div>}
                                                {uploadedFiles[0] && !modal.waiting && 
                                                    <div>{uploadedFiles.map((file, index) =>
                                                        <p className="upload-files-list" key={index}>{file.name}</p>
                                                        )} 
                                                        {markerError && <p className="marker-error-message">{markerError}</p>}
                                                        <div className="marker-bottom-buttons-wrapper">
                                                            <button className="marker-bottom-form-button" onClick={sendCameraImages}>Wyślij</button>
                                                            <button className="marker-bottom-form-button" onClick={removeCameraImages}>Anuluj</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        {markerForm.image &&
                                            <div className="marker-form-wrapper">
                                                <div id="marker-image-wrapper">
                                                    <p className="marker-bottom-form-title">Dodaj zdjęcie:</p>
                                                    <div id="marker-image-form-wrapper" onDragOver={allowDrop} onDrop={handleDrop}>
                                                        <input
                                                            type="text"
                                                            id="drop-zone"
                                                            placeholder='Przeciągnij lub skopiuj zdjęcie tutaj'
                                                            className="drop-zone"
                                                            onPaste={handlePaste}
                                                            readOnly
                                                        />
                                                    </div>
                                                    {markerError && <p className="marker-error-message">{markerError}</p>}
                                                </div>
                                                <div className="marker-bottom-buttons-wrapper">
                                                    <button className="marker-bottom-form-button" onClick={clearMarkerForm}>Anuluj</button>
                                                </div>
                                            </div>
                                        }
                                    </div>}
                                </div>
                            </div>
                            <div id="marker-bottom-menu-outer-wrapper" className={activeMarker.id ? "" : 'marker-bottom-menu-no-margin'}>
                                <div id="marker-bottom-menu-smallscreen">
                                    {activeMarker.id && <img id="marker-bottom-menu-item-large" className="marker-bottom-menu-item" src={MarkerMenu} alt="menu-icon" onClick={()=> showModalBlock()}/>}
                                </div>
                                <div id="marker-bottom-menu-widescreen">
                                    {(!markerForm.active && !draggable && activeMarker.id) && <div className="marker-bottom-menu-wrapper">
                                        {user.role === 'admin' && <>
                                            <img title="Zmień nazwę" className="marker-bottom-menu-item" src={Icon2} alt="menu-icon" onClick={showMarkerRename}/>
                                            <img title="Dodaj opis" className="marker-bottom-menu-item" src={Icon3} alt="menu-icon" onClick={showMarkerDescription}/>
                                            <img title="Przesuń znacznik" className="marker-bottom-menu-item" src={Icon4} alt="menu-icon" onClick={enableMarkerDragging}/>
                                            <img title="Ustaw kolor" className="marker-bottom-menu-item" src={Icon5} alt="menu-icon" onClick={showMarkerColorChange}/>
                                            <img title="Usuń znacznik" className="marker-bottom-menu-item" src={Icon6} alt="menu-icon" onClick={showMarkerDelete}/>
                                            <img title="Dodaj zdjęcia" className="marker-bottom-menu-item" src={Icon7} alt="menu-icon" onClick={showEditPhotos}/>
                                        </>}
                                        {user.role !== 'read' && <>
                                        <>
                                            {/*<label id="take-picture-icon" htmlFor="icon-button-file">
                                                <img title="Dodaj zdjęcie" className="marker-bottom-menu-item" src={Icon9} alt="menu-icon" />
                                            </label>
                                            <input accept="image/*" id="icon-button-file" type="file" capture="environment" onChange={handlePhoto}/>*/}
                                            <label htmlFor="icon-button-file2">
                                                <img title="Dodaj zdjęcia" className="marker-bottom-menu-item" src={Icon10} alt="menu-icon" />
                                            </label>
                                            <input accept="image/*" id="icon-button-file2" type="file" multiple onChange={handlePhotos}/>
                                        </>
                                        <a target="_blank" rel="noreferrer" href={'http://maps.google.com/maps?q='+activeMarker.latitude+','+activeMarker.longitude+'+(My+Point)&z=14&ll='+activeMarker.latitude+','+activeMarker.longitude}><img title="Zaplanuj trasę" className="marker-bottom-menu-item" src={Icon8} alt="menu-icon"/></a>
                                        {(user.role === 'admin' && markerImages[0]) && <img title="Pobierz zdjęcia" id="download-archive" className="marker-bottom-menu-item" src={Icon11} alt="menu-icon" onClick={downloadPhotos}/>}
                                        </>}
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {/* Modal */}
                {modal.show &&
                <div className="modal-overlay">
                    <ClickAwayListener onClickAway={closeModal}>
                        <div>
                            {/* Utworzenie nowej mapy */}
                            {modal.create &&
                                <div id="create-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Utwórz nową mapę</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <span className="form-label">Nazwa mapy:</span>
                                            <input 
                                                className="new-form"
                                                id="project_name"
                                                type="text"
                                                onChange={formChange}
                                                name="project_name"
                                                value={formData.project_name}
                                            />
                                        </div>
                                        <div className="form-line-wrapper">
                                            <span className="form-label">Opis&#40;opcjonalny&#41;:</span>
                                            <textarea 
                                                className="new-form"
                                                id="project_description"
                                                onChange={formChange}
                                                name="project_description"
                                                value={formData.project_description}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="create-map-button" className="form-button" onClick={createMap}>Utwórz</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message form-waiting-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Otwieranie mapy */}
                            {modal.open &&
                                <div id="open-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Wybierz mapę do otwarcia</h2>
                                    </div>
                                    <div className="modal-body">
                                        {!axiosError && projects.map((project) =>
                                        <div key={project.id} className="form-line-wrapper">
                                            <p className="project-name" onClick={() => {openMap(project.id, project.project_name, project.project_description, project.modified, project.center_lat, project.center_lng, project.zoom)}}>{project.project_name}</p>
                                        </div>
                                        )}
                                    </div>
                                    {axiosError && 
                                    <div className="modal-footer">
                                        <p className="axios-error">{axiosError}</p>
                                    </div>
                                    }
                                </div>
                            }
                            {/* Zmiana nazwy mapy */}
                            {modal.rename_map &&
                                <div id="rename-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Zmiana nazwy mapy</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <span className="form-label">Nowa nazwa:</span>
                                            <input 
                                                className="new-form"
                                                id="map_rename"
                                                type="text"
                                                onChange={formChange}
                                                name="map_rename"
                                                value={formData.map_rename}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="rename-map-button" className="form-button" onClick={renameMap}>Zmień</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message form-waiting-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Eksport mapy */}
                            {modal.export_map &&
                                <div id="export-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Eksportuj mapę</h2>
                                    </div>
                                    <div className="modal-body">
                                        {!modalSpin && <>
                                        <div className="form-line-wrapper">
                                            <p className="form-label-message">Proszę wybierz format pliku docelowego:</p>
                                        </div>
                                        <div className="export-line-wrapper">
                                            <input
                                                type="radio"
                                                name="export_format"
                                                value="CSV"
                                                id="csv"
                                                checked={mapExport === "CSV"}
                                                onChange={onRadioChange}
                                            />
                                            <label htmlFor="regular">CSV</label>
                                        </div>
                                        <div className="export-line-wrapper">
                                            <input
                                                type="radio"
                                                name="export_format"
                                                value="KML"
                                                id="kml"
                                                checked={mapExport === "KML"}
                                                onChange={onRadioChange}
                                            />
                                            <label htmlFor="medium">KML</label>
                                        </div>
                                        </>}
                                        {modalSpin && <div className="form-line-wrapper">
                                            <p className="form-label-message">Proszę czekać, trwa generowanie pliku...</p>
                                            <div className="spinner-container">
                                                <Spinner/>
                                            </div>
                                        </div>}
                                    </div>
                                    {!modalSpin && <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="export-map-button" className="form-button" onClick={exportMap}>Eksportuj</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Import mapy */}
                            {modal.import_map &&
                                <div id="import-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Importuj mapę</h2>
                                    </div>
                                    <div className="modal-body">
                                        {!modalSpin && <>
                                        <div id="map-import-wrapper" >
                                            <label htmlFor="kml-file" className="drop-container" onDragOver={allowDrop} onDrop={handleKMLDrop}>
                                                <span className="drop-title">Przeciągnij plik tutaj</span>
                                                lub
                                            <input 
                                                type="file"
                                                onChange={KMLchange}
                                            />
                                            </label>
                                        </div>
                                        </>}
                                        {modalSpin && <div className="form-line-wrapper">
                                            <p className="form-label-message">Proszę czekać, trwa importowanie mapy.<br></br> W zależności od jej wielkości może to potrwać do kilkudziesięciu sekund.</p>
                                            <div className="spinner-container">
                                                <Spinner/>
                                            </div>
                                        </div>}
                                    </div>
                                    {!modalSpin && <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="export-map-button" className="form-button" onClick={importMap}>Importuj</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Usuwanie map */}
                            {modal.delete &&
                                <div id="delete-map-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Wybierz mapy do usunięcia</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        {projects.map((project) =>
                                        <div className="delete-form-line" key={project.id}>
                                            <input onClick={updateDeleteList} type='checkbox' value={project.id} className='delete-checkbox'></input>
                                            <p className="delete-name">{project.project_name}</p>
                                        </div>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="delete-map-button" className="form-button" onClick={deleteMap}>Usuń</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać, trwa usuwanie.</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Zmiana nazwy warstwy */}
                            {modal.rename_layer &&
                                <div id="rename-layer-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Zmiana nazwy warstwy</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <span className="form-label">Nowa nazwa:</span>
                                            <input 
                                                className="new-form"
                                                id="layer_rename"
                                                type="text"
                                                onChange={formChange}
                                                name="layer_rename"
                                                value={formData.layer_rename}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="rename-layer-button" className="form-button" onClick={renameLayer}>Zmień</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Zmiana koloru warstwy */}
                            {modal.color_layer && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Zmień kolor warstwy</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <div id="marker-color-picker-wrapper">
                                                <p className="marker-bottom-form-title">Wybierz kolor warstwy:</p>
                                                <SliderPicker color={color.color} onChangeComplete={({hex}) => setColor({...color, color: hex})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" onClick={changeLayerColor}>Zmień</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Usuwanie warstwy */}
                            {modal.delete_layer &&
                                <div id="delete-layer-modal" className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Usunięcie warstwy</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="form-label-message">Czy na pewno chcesz usunąć wybraną warstwę?<br></br><br></br>Spowoduje to również usunięcie wszystkich znaczników, które ta warstwa zawiera.</p>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error">{axiosError}</p>}
                                        <div className="form-button-wrapper">
                                            <button id="delete-layer-button" className="form-button" onClick={deleteLayer}>Usuń</button>
                                            <button className="form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Mobilne menu */}
                            {modal.mobile_marker_menu &&
                                <div id="mobile-marker-menu-modal" className="modal">
                                    <div className="modal-body">
                                        <span className="mobile-menu-top-border"></span>
                                        {activeMarker.id &&
                                        <>
                                            {user.role === 'admin' && <button className="mobile-marker-menu-button" onClick={showMarkerRename}><img className="mobile-marker-menu-icon" src={Icon2} alt="menu-icon"/>Zmień nazwę</button>}
                                            {user.role !== 'read' && <button className="mobile-marker-menu-button" onClick={showMarkerDescription}><img className="mobile-marker-menu-icon" src={Icon3} alt="menu-icon"/>Dodaj opis</button>}
                                            {user.role === 'admin' && <button className="mobile-marker-menu-button" onClick={enableMarkerDragging}><img className="mobile-marker-menu-icon" src={Icon4} alt="menu-icon"/>Przesuń znacznik</button>}
                                            {user.role === 'admin' && <button className="mobile-marker-menu-button" onClick={showMarkerColorChange}><img className="mobile-marker-menu-icon" src={Icon5} alt="menu-icon"/>Ustaw kolor</button>}
                                            {user.role === 'admin' && <button className="mobile-marker-menu-button" onClick={showMarkerDelete}><img className="mobile-marker-menu-icon" src={Icon6} alt="menu-icon"/>Usuń znacznik</button>}
                                            {user.role !== 'read' && <button className="mobile-marker-menu-button" onClick={()=>window.open('http://maps.google.com/maps?q='+activeMarker.latitude+','+activeMarker.longitude+'+(My+Point)&z=14&ll='+activeMarker.latitude+','+activeMarker.longitude, '_blank')}><img className="mobile-marker-menu-icon" src={Icon8} alt="menu-icon"/>Wyznacz trasę</button>}
                                        </>}
                                        {activeMarker.id && (user.role === 'user' || user.role === 'admin') && <>
                                            <label id="take-picture-icon" className="mobile-marker-menu-button" htmlFor="icon-button-file">
                                                <img className="mobile-marker-menu-icon" src={Icon9} alt="menu-icon" /><span>Zrób zdjęcie</span>
                                            </label>
                                            <input accept="image/*" id="icon-button-file" type="file" capture="environment" onChange={handlePhoto}/>
                                            <label className="mobile-marker-menu-button" htmlFor="icon-button-file2">
                                                <img className="mobile-marker-menu-icon" src={Icon10} alt="menu-icon" /><span>Dodaj zdjęcia</span>
                                            </label>
                                            <input accept="image/*" id="icon-button-file2" type="file" multiple onChange={handlePhotos}/>
                                        </>}  
                                    </div>                                
                                </div>
                            }
                            {/* Zmiana nazwy markera */}
                            {modal.marker_rename && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Zmień nazwę znacznika</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="marker-bottom-form-title">Wpisz nową nazwę:</p>
                                            <input
                                                className="new-form"
                                                id="marker_rename"
                                                type="text"
                                                onChange={handleChange}
                                                name="marker_rename"
                                                value={formData.marker_rename}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" id="rename-marker-button" onClick={renameMarker}>Zmień</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Zmiana opisu markera */}
                            {modal.marker_description && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Ustaw opis znacznika</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="marker-bottom-form-title">Dodaj opis markera:</p>
                                            <textarea
                                                className="new-form"
                                                id="marker_description"
                                                type="text"
                                                onChange={handleChange}
                                                name="marker_description"
                                                value={formData.marker_description}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" id="set-marker-description" onClick={changeMarkerDescription}>Zmień</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Przesuwanie markera */}
                            {modal.marker_move && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Przesuwanie znacznika</h2>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="marker-bottom-form-title">Aby przesunąć znacznik przejdź na okno mapy i postępuj zgodnie z instrukcją.</p>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" onClick={closeModal}>OK</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Zmiana koloru markera */}
                            {modal.marker_color && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Zmień kolor znacznika</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <div id="marker-color-picker-wrapper">
                                                <p className="marker-bottom-form-title">Wybierz kolor znacznika:</p>
                                                <SliderPicker color={color.color} onChangeComplete={({hex}) => setColor({...color, color: hex})}/>
                                            </div>
                                        </div>
                                    </div>
                                    {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                    <div className="modal-footer">
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" onClick={changeMarkerColor}>Zmień</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Usunięcie markera */}
                            {modal.marker_delete && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Usuń znacznik</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="marker-bottom-form-title">Czy na pewno usunąć znacznik?</p>
                                        </div>
                                    </div>
                                    {axiosError && <p className="axios-error axios-error-2">{axiosError}</p>}
                                    <div className="modal-footer">
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" id="delete-marker-button" onClick={deleteMarker}>Usuń</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Usunięcie zdjęcia */}
                            {modal.image_delete && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Usuń zdjęcie</h2>
                                    </div>
                                    {!modalSpin && <>
                                    <div className="modal-body">
                                        <div className="form-line-wrapper">
                                            <p className="marker-bottom-form-title">Czy na pewno usunąć zdjęcie?</p>
                                            
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" id="delete-marker-button" onClick={deleteImage}>Usuń</button>
                                            <button className="marker-bottom-form-button" onClick={closeModal}>Anuluj</button>
                                        </div>
                                    </div>
                                    {axiosError && <p className="marker-error-message">{axiosError}</p>}
                                    </>}
                                    {modalSpin && <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>}
                                </div>
                            }
                            {/* Czekanie */}
                            {modal.waiting && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">{waitingTitle}</h2>
                                    </div>
                                    <div className="modal-spinner-container">
                                        <p className="form-label-message">Proszę czekać</p>
                                        <div className="spinner-container">
                                            <Spinner/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Informacja */}
                            {modal.message && 
                                <div className="modal">
                                    <div className="modal-header">
                                        <h2 className="modal-title">Informacja</h2>
                                    </div>
                                    <div className="modal-message-container">
                                        <p className="form-label-message">{modalMessage}</p>
                                        <p className="form-label-message form-label-error-message">{modalErrorMessage}</p>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="marker-bottom-buttons-wrapper">
                                            <button className="marker-bottom-form-button" onClick={closeModal}>OK</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </ClickAwayListener>
                </div>
                }
            </div>
            }

            

            {/*Panel administracyjny*/}
            {appLayer === 400 && 
            <div id="admin-wrapper">
                <div id="admin-outer-container">
                    <div id="admin-inner-container">
                        <p id="admin-site-title">Lista użytkowników aplikacji</p>
                        {message && <p id="admin-error-message">{message}</p>}
                        {users[0] &&
                        <table id="admin-user-table">
                            <thead>
                                <tr>
                                    <th>Numer</th>
                                    <th>Użytkownik</th>
                                    <th>Dostęp</th>
                                    <th>Akcja</th>
                                </tr>
                            </thead>
                            <tbody>
                            {users.map((user, i) => 
                                <tr key={user.id}>
                                    <td>{i +1}</td>
                                    <td>{user.username}</td>
                                    {user.role === 'admin' && <td>administrator</td>}
                                    {user.role === 'user' && <td>użytkownik</td>}
                                    {user.role === 'read' && <td>odczytujący</td>}
                                    {user.username !== 'tomasz.dziedzic@rubika.pl' ? <td><span className="user-delete" onClick={() => showDelete(user.id)}>Usuń</span></td> : <td></td>}
                                </tr>
                            )}
                            </tbody>
                        </table>}
                        {users[0] &&
                            <>
                                {users.map((user, i) => 
                                <table className="admin-user-table-mobile" key={i}>
                                    <thead>
                                        <tr>
                                            <th>Parametr</th>
                                            <th>Wartość</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>Użytkownik</td>
                                            <td>{user.username}</td>
                                        </tr>
                                        <tr >
                                            <td>Rola</td>
                                            {user.role === 'admin' && <td>administrator</td>}
                                            {user.role === 'user' && <td>użytkownik</td>}
                                            {user.role === 'read' && <td>odczytujący</td>}
                                        </tr>
                                        {user.username !== 'tomasz.dziedzic@rubika.pl' && <tr >
                                            <td colSpan={2}><span className="user-delete" onClick={() => showDelete(user.id)}>Usuń użytkownika</span></td>
                                        </tr>}
                                    </tbody>
                                </table>
                                )}
                            </>
                        }
                        <button id="add-user-button" onClick={() => setAdminModal({...modal, show: true, create: true})}>Dodaj użytkownika</button>
                    </div>
                </div>
                {adminModal.show &&
                    <div className="modal-overlay">
                        <ClickAwayListener onClickAway={closeAdminModal}>
                        <div>
                        { adminModal.create &&
                            <div id="create-user-modal" className="modal">
                                <div className="modal-header">
                                    <h2 className="modal-title">Dodaj nowego użytkownika</h2>
                                </div>
                                <div className="modal-body">
                                    <div className="form-line-wrapper">
                                        <span className="form-label">Nazwa użytkownika:</span>
                                        <input 
                                            className="user-form"
                                            id="username"
                                            type="text"
                                            onChange={adminFormChange}
                                            name="username"
                                            value={adminFormData.username}
                                        />
                                    </div>
                                    <div className="form-line-wrapper">
                                        <span className="form-label">Hasło:</span>
                                        <input 
                                            className="user-form"
                                            id="password"
                                            type="password"
                                            onChange={adminFormChange}
                                            name="password"
                                            value={adminFormData.password}
                                        />
                                    </div>
                                    <div className="form-line-wrapper">
                                        <span className="form-label">Rola w aplikacji:</span>
                                        <select 
                                                id="user-role-select"
                                                value={adminFormData.role}
                                                onChange={adminFormChange}
                                                name="role"
                                            >
                                                <option value="">Wybierz</option>
                                                <option value="admin">Administrator</option>
                                                <option value="user">Użytkownik</option>
                                                <option value="read">Odczyt</option>
                                        </select>
                                    </div>
                                    {message && <p className="user-error-message">{message}</p>}
                                </div>
                                <div className="modal-footer">
                                    <div className="form-button-wrapper">
                                        <button id="create-map-button" className="form-button" onClick={createUser}>Utwórz</button>
                                        <button className="form-button" onClick={closeAdminModal}>Anuluj</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {adminModal.delete && 
                            <div id="delete-user-modal" className="modal">
                                <div className="modal-header">
                                    <h2 className="modal-title">Usunięcie użytkownika</h2>
                                </div>
                                <div className="modal-body">
                                    <div className="form-line-wrapper">
                                        <p className="form-label">Czy na pewno chcesz usunąć wybranego użytkownika.</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {message && <p className="user-error-message">{message}</p>}
                                    <div className="form-button-wrapper">
                                        <button id="delete-layer-button" className="form-button" onClick={removeUser}>Usuń</button>
                                        <button className="form-button" onClick={closeAdminModal}>Anuluj</button>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                        </ClickAwayListener>
                    </div>
                }
            </div>  
            }
            <footer>
                <div id="footer-wrapper">
                    <p id='footer-text'>&copy; Rubika Consulting - {year}</p>
                </div>
            </footer>
        </>
    );
};