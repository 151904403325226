import React, { useState } from "react";
import { Axios } from './UserContext';


export default function Old(){

    const [message, setMessage] = useState(null);

    function connectInternet(){
        setMessage(null);
        Axios.get('webcam1/modem.php', { timeout: 20000 }).then(function(response){
            if(response.data){
                setMessage(response.data)    
            } else {
                setMessage('Proces zakończył się niepowodzeniem');
            }
        }).catch((error) => {
            setMessage('Proces zakończył się niepowodzeniem');
            console.log(error);
        });
    }

    return (
        <div id="login-outer-container">
            <div id="login-inner-container">
                <button className="app1-main-button app1-main-button-centered" onClick={() => connectInternet()}>Połącz internet</button>
                {message && <p className='raspberry-message-span raspberry-message-span-centered'>{message}</p>}
            </div>
        </div>
    );
};