import {createContext, useState, useEffect} from 'react';
import axios from 'axios';
import { db } from "./Db";

export const UserContext = createContext();

export const Axios = axios.create({
    baseURL: 'php/',
});
/*
export const Axios = axios.create({
    baseURL: 'php-webcam/',
});
*/
export const UserContextProvider = ({children}) => {

    const [theUser, setUser] = useState({name: '', role: 'empty'});

    const [wait, setWait] = useState(false);

    // Logowanie

    const loginUser = async (formData) => {
        setWait(true);
        try{
            const {data} = await Axios.post('login.php', {formData});

            if(data.token){
                localStorage.setItem('loginToken', data.token);
                setWait(false);
                return {message:'Zaraz nastąpi przekierowanie.'};
            }
            setWait(false);
            return {message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }

    }

    const loggedInCheck = async () => {

        const loginToken = localStorage.getItem('loginToken');

        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        if(loginToken){
            const {data} = await Axios.get('getUser.php');
            if(data.success && data.user){
                setUser({name: data.user[0].username, role: data.user[0].role});
                db.user.toArray().then(function(result){
                    if(result.length > 0){
                        db.user.update(1, {'username':data.user[0].username});
                    } else {
                        db.user.put({'index': 1, 'username':data.user[0].username});
                    }
                });
            } else if(data.success === 0 && data.message === 'No connection'){
                checkUserOffline();
            } else {
                setUser({name: '', role: 'none'});
            }
        } else {
            setUser({name: '', role: 'none'});
        }

    }

    function checkUserOffline(){
        db.user.toArray().then(function(result){
            if(result.length > 0){
                let username = result[0].username
                setUser({name: username, role: 'user'});
            } else {
                setUser({name: '', role: 'none'});
            }
        });
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
        // eslint-disable-next-line
    },[]);

    const logout = () => {
        localStorage.removeItem('loginToken');
        setUser({name: '', role: 'none'});
        window.location.reload(true);
    }

    



    /*

    const [theUser, setUser] = useState({name: '', role: 'empty'});

    const [wait, setWait] = useState(false);

    // Logowanie

    const loginUser = async (formData) => {

        setWait(true);

        try{
            const {data} = await Axios.post('login.php', {formData});

            if(data.token){
                localStorage.setItem('loginToken', data.token);
                setWait(false);
                return {message:'Zaraz nastąpi przekierowanie.'};
            }
            setWait(false);
            return {message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Brak połączenia!'};
        }

    }

    const loggedInCheck = async () => {

        const loginToken = localStorage.getItem('loginToken');

        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        if(loginToken){

            try{
                const {data} = await Axios.get('getUser.php');
    
                if(data.success && data.user){
                    setUser({name: data.user[0].username, role: data.user[0].role});
                    db.user.toArray().then(function(result){
                        if(result.length > 0){
                            db.user.update(1, {'username':data.user[0].username});
                        } else {
                            db.user.put({'index': 1, 'username':data.user[0].username});
                        }
                    });
                } else if(data.success === 0 && data.message === 'No connection'){
                    checkUserOffline();
                } else {
                    setUser({name: '', role: 'none'});
                }
            } catch(err){
                checkUserOffline();
            }
            
        }

    }

    function checkUserOffline(){
        db.user.toArray().then(function(result){
            if(result.length > 0){
                let username = result[0].username
                setUser({name: username, role: 'user'});
            } else {
                setUser({name: '', role: 'none'});
            }
        });
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
        // eslint-disable-next-line
    },[]);

    const logout = () => {
        localStorage.removeItem('loginToken');
        setUser({name: '', role: 'none'});
        window.location.reload(true);
    }

    */

    return (
        <UserContext.Provider value={{loginUser,wait, user:theUser,loggedInCheck,logout}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;